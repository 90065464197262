import { UPLOAD_BUTTON } from '../../../types/fieldsTypes'

export default {
  title: 'Untitled',
  name: 'Untitled',
  type: UPLOAD_BUTTON,
  group: 'advanced',
  properties: {
    size: {
      description: 'Display size',
      values: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      selected: {
        id: 'small'
      }
    },
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    type: {
      description: '...',
      values: [
        {
          id: 'single',
          title: 'Single'
        },
        {
          id: 'multi',
          title: 'Multi'
        }
      ],
      selected: {
        id: 'single'
      }
    },
    label: {
      selected: 'Upload'
    },
    buttonColor: {
      selected: '#00D1FF'
    },
    labelColor: {
      selected: '#fff'
    },
    predefined: {
      optional: true,
      selected: null
    },
    placeholder: {
      optional: true,
      selected: null
    },
    instructions: {
      selected: null
    },
    system: {
      selected: false
    }
  }
}
