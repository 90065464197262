export default {
  title: 'Untitled',
  name: 'Untitled',
  type: 'actionButton',
  group: 'advanced',
  properties: {
    size: {
      description: 'Display size',
      values: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      selected: {
        id: 'small'
      }
    },
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    type: {
      description: '...',
      values: [
        {
          id: 'url',
          title: 'Open URL'
        },
        {
          id: 'email',
          title: 'Send Email Message'
        },
        {
          id: 'payment',
          title: 'Make a Payment'
        }
      ],
      selected: {
        id: 'url'
      }
    },
    url: {
      values: [
        {
          id: 'current',
          title: 'Same window'
        },
        {
          id: 'new',
          title: 'New window'
        }
      ],
      selected: {
        link: 'http://www.impactfactors.net',
        target: {
          id: 'new'
        }
      }
    },
    email: {
      sizes: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      range: {
        formats: [
          {
            id: 'character',
            title: 'Characters'
          },
          {
            id: 'word',
            title: 'Words'
          }
        ]
      },
      selected: {
        address: 'example@mail.com',
        size: {
          id: 'medium'
        },
        range: {
          id: 'character',
          min: 0,
          max: null
        }
      }
    },
    label: {
      selected: 'Button'
    },
    buttonColor: {
      selected: '#00D1FF'
    },
    labelColor: {
      selected: '#fff'
    },
    predefined: {
      optional: true,
      selected: null
    },
    placeholder: {
      optional: true,
      selected: null
    },
    instructions: {
      selected: null
    },
    system: {
      selected: false
    }
  }
}
