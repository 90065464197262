export const FORM = {
  title: 'Untitled Form',
  type: 'custom',
  description: 'My new form. Please read and fill it.',
  showTitle: true,
  showDescription: true,
  header_alignment: 4,
  field_label_alignment: 0,
  tos_enabled: false,
  tos_required: false, // false,
  tos_content: 'Please accept the Term of Services to confirm',
  tos_type: 0,
  tos_link: 'http://www.impactfactors.net',
  tos_link_label: 'Terms of Service',
  tos_confirmation_content: 'Terms of Service',
  // tos_user_required: 1, // нет в доке
  confirmation_default: true,
  confirmation_button: 1, // 0 // возможно переписать под 1 и 2 вместо 0 и 1
  confirmation_button_confirm_text: 'Confirm',
  confirmation_button_confirm_color: '#00D1FF',
  confirmation_button_confirm_label_color: '#fff',
  confirmation_button_cancel_text: 'Cancel',
  confirmation_button_cancel_color: '#00D1FF',
  confirmation_button_cancel_label_color: '#fff',
  confirmation_after_action: 0,
  confirmation_after_content: 'Thank you for filling out my form!',
  confirmation_after_link: 'http://www.impactfactors.net',
  confirmation_after_link_target: 0, // 0: current window, 1: new window
  confirmationEmailSend: false,
  participant_category_use: '',
  participant_category: '',
  confirmationEmailFromName: '',
  confirmationEmailReplyTo: '',
  confirmationEmailSubject: '',
  confirmationEmailBody: '',
  confirmationEmailBcc: '',
  adminNotificationSubject: '',
  adminNotificationSend: true,
  adminNotificationEmail: '',
  adminNotificationSendSubmissionData: '',
  authNeeded: false,
  useCaptcha: true,
  submitButtonDisabled: true,
  system: false,
  fields: [],
  tags: []
}

// termOfServices: {
//   useTerms: false,
//   type: 'text',
//   confirmationText: 'Please accept the Term of Services to confirm',
//   link: 'Terms of Service',
//   text: 'Terms of Service',
//   url: 'http://',
//   require: [true]
// },
// confirmButtons: {
//   customize: false,
//   useTwoButtons: false,
//   firstButtonText: 'Confirm',
//   firstButtonId: 'id1',
//   firstButtonColor: '#00D1FF',
//   firstButtonTextColor: '#fff',
//   secondButtonText: 'Cancel',
//   secondButtonId: 'id2',
//   secondButtonColor: '#00D1FF',
//   secondButtonTextColor: '#fff'
// },  // afterConfirmation: {
//   showConfirmationText: true,
//   text: 'Thank you for filling out my form!',
//   redirect: 'http://',
//   openInNewWindow: [],
//   confirmEmail: true
// },
// category: {
//   use: false,
//     value: ''
// },
