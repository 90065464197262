import * as listTypes from '@@@/preset/listElements'
import * as listElements from '@@@/preset/settings/listElements'
import * as listSubTypes from '@@@/preset/elements/listSubTypes'
import { GENERAL_SETTINGS, POSITION } from '@@@/preset/settings/listElements'

// let currentFont = 'Trebuchet MS'
let currentFont = 'SourceSansPro-Regular'
let currentSeoTitle = 'New Site'
let currentMetaDescription = 'New Site Description'
let currentMetaKeywords = 'New, Site'

function getFont () {
  return currentFont
}

export function setFont (data) {
  currentFont = data
}

function getCurrentSeoTitle () {
  return currentSeoTitle
}

export function setCurrentSeoTitle (data) {
  currentSeoTitle = data
}

function getCurrentMetaDescription () {
  return currentMetaDescription
}

export function setCurrentMetaDescription (data) {
  currentMetaDescription = data
}

function getCurrentMetaKeywords () {
  return currentMetaKeywords
}

export function setCurrentMetaKeywords (data) {
  currentMetaKeywords = data
}

export function getFontFamily () {
  return getFont()
}

export const FONT_FAMILIES_GLOBAL = getFont
export const SEO_TITLE = getCurrentSeoTitle
export const SEO_META_DESCRIPTION = getCurrentMetaDescription
export const SEO_META_KEYWORDS = getCurrentMetaKeywords

export function SET_DEFAULTS_AFTER_LOADING (resp) {
  setFont(resp.data.structure.siteSettings.fontFamily)
  setCurrentSeoTitle(resp.data.structure.siteSettings.webSiteTitle)
  setCurrentMetaDescription(resp.data.structure.siteSettings.webSiteDescription)
  setCurrentMetaKeywords(resp.data.structure.siteSettings.metaKeyWords)
}

export const ELEMENTS_ACCORDION_SETTINGS = {
  [listSubTypes.SUB_TYPE_BRIXTON_SANS1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS12_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS13_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS14_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS15_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS16_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS17_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS18_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS19_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS20_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS21_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS22_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS23_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS24_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS25_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS26_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS27_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS28_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS29_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS30_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS31_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS32_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS33_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS34_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS35_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS36_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS37_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS38_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS39_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS40_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS41_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS42_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS43_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS44_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS45_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS46_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS47_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIXTON_SANS48_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL12_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL13_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL14_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL15_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL16_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL17_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL18_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL19_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL20_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL21_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL22_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL23_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL24_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL25_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL26_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL27_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL28_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL29_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL30_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL31_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL32_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL33_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL34_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL35_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL36_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL37_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL38_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL39_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL40_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL41_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL42_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL43_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL44_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL45_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL46_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL47_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL48_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL49_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL50_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL51_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL52_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL53_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL54_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL55_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL56_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL57_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL58_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL59_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL60_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL61_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL62_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL63_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL64_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL65_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL66_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL67_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL68_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL69_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL70_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL71_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL72_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL73_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL74_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_JOVIAL75_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOGO6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON12_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON13_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BRIHTON14_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SWASH10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT12_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT13_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT14_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT15_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT16_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT17_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT18_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT19_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT20_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT21_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT22_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT23_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT24_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT25_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT26_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT27_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT28_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT29_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT30_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT31_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT32_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT33_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT34_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT35_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT36_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT37_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT38_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT39_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT40_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT41_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT42_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT43_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT44_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT45_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT46_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT47_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT48_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT49_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT50_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT51_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT52_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT53_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT54_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT55_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT56_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT57_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT58_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT59_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT60_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT61_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT62_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT63_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT64_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT65_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT66_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT67_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT68_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT69_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT70_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT71_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT72_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT73_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT74_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT75_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT76_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT77_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT78_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT79_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT80_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT81_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT82_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT83_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT84_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT80_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT85_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT80_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT86_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT87_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT88_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT89_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT90_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT91_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT92_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT93_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT94_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT95_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT96_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT97_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT98_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT99_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT100_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT101_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT102_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT103_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT104_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT105_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT106_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT107_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT108_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT109_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT110_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT111_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT112_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT113_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT114_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT115_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT116_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT117_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT118_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT119_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT120_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT121_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT122_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT123_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT124_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT125_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT126_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT127_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT128_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT129_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT130_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT131_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ORNAMENT132_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listTypes.TYPE_SECTION]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true

  },
  [listTypes.TYPE_ROW]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.SLIDERS_SETTINGS]: true,
    [listElements.TABLE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true,
    [listElements.BLOCK_TEMPLATE_SETTINGS]: true,
    [listElements.SET_CUSTOM_CLASS]: true
  },
  [listTypes.TYPE_COLUMN]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_HEADLINE]: {
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: false,
    [listElements.FILTERS]: false,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_ADDRESS_COPY]: {
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: false,
    [listElements.FILTERS]: false,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SUBHEADLINE]: {
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: false,
    [listElements.FILTERS]: false,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_HEADER]: {
    [listElements.GENERAL_HEADER_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TEXT_BLOCK]: {
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: false,
    [listElements.FILTERS]: false,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.GENERAL_SETTINGS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_BUTTON]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CUSTOM_LOGO]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: false,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.FILTERS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_HEADER_BUTTON]: {
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.MENU_STYLE]: true
  },
  [listSubTypes.SUB_TYPE_PAGE_BUTTON]: {
    [listElements.HEADER_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.MENU_STYLE]: true
  },
  [listSubTypes.SUB_TYPE_SHAPES_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ISOSCELES_TRIANGLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_OVAL]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SQUARE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TRAPEZE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DECAGON_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TRIANGLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_PENTAGON_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ROUNDED_CORNER_SQUARE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_OCTAGON_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_OCTAGRAM_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_HEXAGON_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUMPY_CIRCLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DIAMOND_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_GEM_STONE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_HEART_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_HOLLOW_CIRCLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LOCATION_PIN_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ROUND_TALK_BUBBLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SEAL_STAMP_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SEMICIRLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SHIELD1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SHIELD2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SHIELD3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPEECH_BUBBLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SQUARE_STAMP_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_STAR_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_STAR_SHAPE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TALK_BUBBLE_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TRIANGLE_ARROW_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUDGE11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RAIN_DROP_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RIBBON6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SMILE1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SMILE2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SMILE3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SMILE4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SMILE5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SPECH8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS1_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS2_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS3_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS4_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS5_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS6_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS7_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS8_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS9_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS10_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS11_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS12_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS13_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS14_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS15_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS16_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS17_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS18_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WEB_ARROWS19_BLOCKS_ONE]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BUTTON_FRAMEWORK]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.LINK_TO_FW_SETTINGS]: true,
    [listElements.GENERAL_FW_BUTTON_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_IFRAME]: {
    [listElements.IFRAME_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DIVIDER]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.POSITION]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DIVIDER_VERTICAL]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.POSITION]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_IMAGE]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.FILTERS]: false,
    [listElements.BACKGROUND_SETTINGS]: false,
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.SMART_ASSISTANT]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.VARIABLES_SETTINGS]: false,
    [listElements.SET_CUSTOM_CLASS]: false,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ICON]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.FILTERS]: true,
    [listElements.LINK_TO_SETTINGS]: false,
    [listElements.SMART_ASSISTANT]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.SET_CUSTOM_CLASS]: true
  },
  [listSubTypes.SUB_TYPE_DIRECTIONS_ICON]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CLOCK_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_MAP_TEMPLATE]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.MAP_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_READING_SECTION]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_BG]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_BANNER]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_MINI]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_NO_BG]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR_IMG]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.SECTION_TEMPLATE_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_DEVOTIONAL_SECTION]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MISSION]: {
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MINISTRY]: {
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MINISTRY2]: {
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SLIDER]: {
    [listElements.SLIDER_SETTINGS]: true,
    [listElements.SLIDERS_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: false,
    [listElements.FILTERS]: false,
    [listElements.ANIMATION_SETTINGS]: false,
    [listElements.SET_CUSTOM_CLASS]: false,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SOCIAL]: {
    [listElements.SOCIAL_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SOCIAL_PANEL]: {
    [listElements.SOCIAL_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_NEW_DESIGN_SOCIAL_PANEL]: {
    [listElements.SOCIAL_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_VIDEO]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_1]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_PULL_OUT]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_2]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_3]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_4]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_5]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_6]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_7]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MENU_LIST_8]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.MENU_SETTINGS]: true,
    [listElements.HEADER_SETTINGS]: true,
    // link: true,
    // linkStyle: true,
    // authModalStyle: true,
    [listElements.MENU_STYLE]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER]: {
    [listElements.ANIMATION_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SECTION_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER_SMALL]: {
    [listElements.ANIMATION_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER_ONE]: {
    [listElements.ANIMATION_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER_TWO]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.FOOTER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER_THREE]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.FOOTER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_FOOTER_EIGHT]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.FOOTER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_FOOTER_FOUR]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.FOOTER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FOOTER_FIVE]: {
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.FOOTER_SETTINGS]: true,
    link: true,
    linkStyle: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_FOOTER_SIX]: {
    [listElements.FOOTER_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_FOOTER_SEVEN]: {
    [listElements.FOOTER_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_TABLE]: {
    [listElements.TABLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_FORM]: {
    [listElements.SHAPE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.FORM_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_WIDGET]: {
    [listElements.EVENTS_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_EVENTS_WIDGET_COMPACT]: {
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.EVENTS_COMPACT_SUB_SETTINGS]: true,
    [listElements.EVENTS_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_BLOG_WIDGET]: {
    [listElements.BLOG_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BLOG_COMPACT_WIDGET]: {
    [listElements.BLOG_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_QUOTE_WIDGET]: {
    [listElements.BLOG_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_WATCH_US_ON_TV_TYPE2_WIDGET]: {
    [listElements.BLOG_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_PODCASTS]: {
    [listElements.BLOG_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_NEWS_WIDGET]: {
    [listElements.NEWS_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_NEWS_COMPACT_WIDGET]: {
    [listElements.NEWS_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_DEVOTIONS_WIDGET]: {
    [listElements.DEVOTIONS_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_DEVOTIONS_COMPACT_WIDGET]: {
    [listElements.DEVOTIONS_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_READING_PLAN_WIDGET]: {
    [listElements.READING_PLAN_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_READING_PLAN_COMPACT_WIDGET]: {
    [listElements.READING_PLAN_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_PHOTO_WIDGET]: {
    [listElements.PHOTO_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_PHOTO_COMPACT_WIDGET]: {
    [listElements.PHOTO_COMPACT_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CONTACT_US_WIDGET]: {
    [listElements.CONTACT_US_WIDGET_SETTINGS]: true,
    [listElements.CONTACT_US_WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_ABOUT_US_WIDGET]: {
    [listElements.ABOUT_US_WIDGET_SETTINGS]: true,
    [listElements.ABOUT_US_WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_MINISTRIES_WIDGET]: {
    [listElements.MINISTRIES_WIDGET_SETTINGS]: true,
    [listElements.MINISTRIES_WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_STAFF_WIDGET]: {
    [listElements.STAFF_WIDGET_SETTINGS]: true,
    [listElements.STAFF_WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_VISITOR_WIDGET]: {
    [listElements.VISITOR_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_WATCH_US_ON_TV_WIDGET]: {
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.WATCH_US_ON_TV_WIDGET_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SUBSCRIBE_WIDGET]: {
    [listElements.SUBSCRIBE_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_WELCOME_WIDGET]: {
    [listElements.WELCOME_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_MOBILE_APP_WIDGET]: {
    [listElements.MOBILE_APP_WIDGET_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_TV_APP_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.TV_APP_WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_APP_PAGE_WIDGET]: {
    [listElements.APP_PAGE_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_GIVE_WIDGET]: {
    [listElements.GIVE_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CONTENT_WIDGET_ITEM]: {
    [listElements.CONTENT_ITEM_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CONTENT_WIDGET_LIST]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.FILTERS]: true,
    [listElements.LAYOUT_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_STREAM_WIDGET]: {
    [listElements.STREAM_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SERMONS_COMPACT_WIDGET]: {
    [listElements.SERMONS_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_LATEST_COMPACT_WIDGET]: {
    [listElements.LATEST_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LATEST_WIDGET_TEXT_SETTINGS]: true,
    [listElements.LATEST_WIDGET_TITLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_ACCORDION]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ACCORDION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_INPUT]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LINK_TO_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TABS]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.GENERAL_TABS_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CHECKBOX]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.FW_API_PRESET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_RADIO]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TOGGLE]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TOOLTIP]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SELECT]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TEXTAREA]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DATAPICKER]: {
    [listElements.GENERAL_INPUTS_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SERMONS_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SERMONS_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SERMONS_WIDGET2]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SERMONS_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_PRAYER_WALL_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.SERMONS_WIDGET_SETTINGS]: false,
    [listElements.TYPOGRAPHY_SETTINGS]: false,
    [listElements.NIGHT_MODE_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_VIDEO_FULL]: {
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SAVE_TEMPLATE_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LIVE_NEW_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SERMONS_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LIVE_STREAMING_WIDGET]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.SERMONS_WIDGET_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LIVE_STREAMING_WIDGET2]: {
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.DEVOTIONS_WIDGET_SETTINGS]: true,
    [listElements.COLOR_OVERLAY]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_PRIVACY_POLICY_WIDGET]: {
    [listElements.PRIVACY_POLICY_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_COUNTER]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DROPDOWN]: {
    [listElements.GENERAL_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.DROPDOWN_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_NEWS_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_BLOG_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_SERMONS_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_MINISTRIES_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET1]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET2]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LATEST_UPDATE_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_READING_PLAN_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CHART_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_QUICK_FACTS_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_COUNTDOWN_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_ACCORDION_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_BLOCKQUOTE_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_HOW_IT_WORKS_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SLIDER_HORIZONTAL_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_SLIDER_HORIZONTAL_WIDGET_TABS]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_TABS_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_COLUMNS_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_LISTS_COLUMNS_WIDGET]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_LIST_EL]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CHART_EL]: {
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_GROUP]: {
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_CONTENT_BLOCK_CONTAINER]: {
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CONTENT_LIST_BLOCK_CONTAINER]: {
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CONTENT_BLOCK]: {
    [listElements.POSITION]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },

  [listSubTypes.SUB_TYPE_CONTENT_LIST_BLOCK]: {
    [listElements.POSITION]: true,
    [listElements.BACKGROUND_SETTINGS]: true,
    [listElements.VISIBLE_ADAPTIVE_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_PAYMENT_WIDGET]: {
    [listElements.POSITION]: true,
    [listElements.WIDGET_STYLE]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_DONATION_WIDGET]: {
    [listElements.POSITION]: true,
    [listElements.WIDGET_STYLE]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: true,
    [listElements.STANDARD_HORIZONTAL_WIDGET_SETTINGS]: true,
    [listElements.ANIMATION_SETTINGS]: true,
    [listElements.LAYOUT_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_CTA_WIDGET]: {
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.CTA_WIDGET_SETTINGS]: false,
    [listElements.WIDGET_STYLE_SETTINGS]: true,
    [listElements.TYPOGRAPHY_SETTINGS]: false,
    [listElements.SAVE_TEMPLATE_SETTINGS]: false
  },
  [listSubTypes.SUB_TYPE_QR]: {
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.POSITION]: false,
    [listElements.QR_SETTINGS]: true
  },
  [listSubTypes.SUB_TYPE_WIDGET_IMAGE]: {
    [listElements.LAYOUT_SETTINGS]: false,
    [listElements.POSITION]: false
  },

  sidebarLeftElements: {
    accordionLayout: true,
    accordionBasic: true,
    accordionTypography: true,
    accordionMedia: true,
    accordionForms: false,
    accordionComponents: false,
    accordionTemplates: false,
    accordionWidgets: false,
    preBuilds: {}
  }
}
