export const langOptions = ['UK', 'spain', 'france', 'germany', 'russia', 'italy']
export const genderOptions = ['Male', 'Female']
export const peoplePrayed = ['5 people prayed', '10 people prayed', '25 people prayed', '50 people prayed', '100 people prayed', '250 people prayed', '1000 people prayed', '10000 people prayed']
export const martialStatusOptions = ['Married', 'Single']
export const monthsOptions = ['Not Selected', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const daysOptionsFebruary = ['Not Selected', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29']
export const daysOptionsShort = ['Not Selected', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
export const daysOptionsLong = ['Not Selected', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
export const sexOptions = ['Not Selected', 'Male', 'Female']
export const statusOptions = ['Not Selected', 'Married', 'Single', 'Divorced']
export const cityOptions = ['Not Selected', 'City 2', 'City 3']
export const stateOptions = ['Not Selected', 'State 1', 'State 2']
export const searchOptions = ['Word', 'User']
export const yearsOptions = ['Not Selected']
for (let i = 1950; i <= new Date().getFullYear(); i++) {
  yearsOptions.push(`${i}`)
}
export const countryOptions = [{
  text: 'Not Selected',
  key: 'Not Selected'
}]

export const CAMPUS_OPTIONS = [{
  text: 'Main Campus',
  key: 'Main Campus'
}]

const tabTitle = {
  PRAYER_REQUEST: 'Prayer Requests',
  PRAISE_REPORT: 'Praise Reports',
  MY_POSTS: 'My Posts',
  CHURCH_FINDER: 'Fellowship Finder',
  PROFILE: 'Profile'
}

const tabMobileTitle = {
  PRAYER_REQUEST: 'Prayers',
  PRAISE_REPORT: 'Praise',
  MY_POSTS: 'My Posts',
  CHURCH_FINDER: 'Fellowship Finder',
  PROFILE: 'Profile'
}

export const tabList = [
  {
    title: tabTitle.PRAYER_REQUEST,
    mobileTitle: tabMobileTitle.PRAYER_REQUEST,
    isIcon: true,
    titleKey: 'requests',
    iconName: 'prayers-group'
  },
  {
    title: tabTitle.PRAISE_REPORT,
    mobileTitle: tabMobileTitle.PRAISE_REPORT,
    isIcon: true,
    titleKey: 'reports',
    iconName: 'reports'
  },
  {
    title: tabTitle.MY_POSTS,
    mobileTitle: tabMobileTitle.MY_POSTS,
    isIcon: true,
    titleKey: 'posts',
    iconName: 'posts'
  },
  {
    title: tabTitle.CHURCH_FINDER,
    mobileTitle: tabMobileTitle.CHURCH_FINDER,
    isIcon: true,
    titleKey: 'churchFinder',
    iconName: 'church_finder',
    showCheckKey: 'impact.church_finder'
  },
  {
    title: tabTitle.PROFILE,
    mobileTitle: tabMobileTitle.PROFILE,
    isIcon: true,
    titleKey: 'profile',
    iconName: 'profile'
  }
]

export const accordions = [
  {
    name: 'By category',
    checkbocxs: [
      // {
      //   id: 'category1',
      //   text: 'Category 1'
      //
      // },
      // {
      //   id: 'category2',
      //   text: 'Category 2'
      // },
      // {
      //   id: 'category3',
      //   text: 'Category 3'
      // }
    ]
  },
  // {
  //   name: 'By Keywords',
  //   checkbocxs: [
  //     {
  //       id: 'keyword1',
  //       text: 'Keyword 1',
  //       isChecked: false
  //     },
  //     {
  //       id: 'keyword2',
  //       text: 'Keyword 2',
  //       isChecked: false
  //     }
  //   ]
  // },
  {
    name: 'By Priority',
    checkbocxs: [
      {
        id: 'regular',
        text: 'Regular',
        isChecked: true
      },
      {
        id: 'urgent',
        text: 'Urgent',
        isChecked: true
      }
    ]
  },
  {
    name: 'Prayed',
    checkbocxs: [
      {
        id: 'prayed-yes',
        text: 'Prayed',
        isChecked: false
      },
      {
        id: 'prayed-no',
        text: 'Not Prayed',
        isChecked: false
      }
    ]
  },
  {
    name: 'By Day',
    checkbocxs: [
      {
        id: 'monday',
        text: 'Monday',
        isChecked: false
      },
      {
        id: 'tuesday',
        text: 'Tuesday',
        isChecked: false
      },
      {
        id: 'wednesday',
        text: 'Wednesday',
        isChecked: false
      },
      {
        id: 'thursday',
        text: 'Thursday',
        isChecked: false
      },
      {
        id: 'friday',
        text: 'Friday',
        isChecked: false
      },
      {
        id: 'saturday',
        text: 'Saturday',
        isChecked: false
      },
      {
        id: 'sunday',
        text: 'Sunday',
        isChecked: false
      }
    ]
  }
  // {
  //   name: 'Commented',
  //   checkbocxs: [
  //     {
  //       id: 'commented-yes',
  //       text: 'Yes',
  //       isChecked: false
  //     },
  //     {
  //       id: 'commented-no',
  //       text: 'No',
  //       isChecked: false
  //     }
  //   ]
  // },
  // {
  //   name: 'Prayed',
  //   checkbocxs: [
  //     {
  //       id: 'prayed-yes',
  //       text: 'Yes',
  //       isChecked: false
  //     },
  //     {
  //       id: 'prayed-no',
  //       text: 'No',
  //       isChecked: false
  //     }
  //   ]
  // }
]

export const churchFinderSortData = [
  {
    id: 'asc',
    name: 'A-Z'
  },
  {
    id: 'desc',
    name: 'Z-A'
  }
]
