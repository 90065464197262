import prayerWall from './modules/prayerWall'
import modeView from './modules/modeView'
import formValidator from './modules/formValidator'

const modules = {
  prayerWall,
  modeView,
  formValidator // TODO: Should resolve as this causes duplicate entry in the store when included in customization and in site renderer
}

export default modules
