import { TYPE_SECTION } from '@@@/preset/listElements'
import { SUB_TYPE_PRAYER_WALL_WIDGET } from '@@@/preset/elements/listSubTypes'
import {
  LAYOUT_SETTINGS,
  WIDGET_STYLE_SETTINGS,
  SERMONS_WIDGET_SETTINGS,
  TYPOGRAPHY_SETTINGS,
  NIGHT_MODE_SETTINGS
} from '@@@/preset/settings/listElements'
import { getUniqueId } from '@@@/mixin/uniqueId'
import { MODE_PC, MODE_TABLET, MODE_MOBILE } from '@@@/store/constant/modeViewNames'
import { FONT_FAMILIES_GLOBAL } from '@@@/preset/globalSettings'

const widgetStyleSettings = (isCustomWidget) => ({
  isDemoContent: true,

  backgroundDark: '#151718',
  backgroundGlobalDark: 'mainColor',
  useBackgroundGlobalDark: false,
  useBackgroundGlobal: false,
  backgroundGlobal: 'mainColor',
  background: '#fff',

  backgroundCardGlobalDark: 'mainColor',
  useBackgroundCardGlobalDark: false,
  useBackgroundCardGlobal: false,
  backgroundCardGlobal: 'mainColor',
  backgroundCardDark: '#24282A',
  backgroundCard: '#f3f8fa',

  buttonColorGlobalDark: 'mainColor',
  useButtonColorGlobalDark: false,
  useButtonColorGlobal: false,
  buttonColorGlobal: 'mainColor',
  buttonColorDark: '#fff',
  buttonColor: '#fff',

  buttonCardColorGlobalDark: 'mainColor',
  useButtonCardColorGlobalDark: false,
  useButtonCardColorGlobal: false,
  buttonCardColorGlobal: 'mainColor',
  buttonCardColorDark: '#fff',
  buttonCardColor: '#fff',

  buttonCardBackgroundGlobalDark: 'lightShades',
  useButtonCardBackgroundGlobalDark: true,
  useButtonCardBackgroundGlobal: true,
  buttonCardBackgroundGlobal: 'lightShades',
  buttonCardBackgroundDark: '#FF6239',
  buttonCardBackground: '#FF6239',

  accentColorGlobalDark: 'mainColor',
  useAccentColorGlobalDark: !isCustomWidget,
  accentColorGlobal: 'lightAccent',
  useAccentColorGlobal: !isCustomWidget,
  accentColorDark: '#2dbeb5',
  accentColor: '#2dbeb5',

  additionalColorGlobalDark: 'mainColor',
  useAdditionalColorGlobalDark: !isCustomWidget,
  additionalColorGlobal: 'lightShades',
  useAdditionalColorGlobal: !isCustomWidget,
  additionalColorDark: '#FF6239',
  additionalColor: '#FF6239',

  textColorGlobalDark: 'darkShades',
  textColorGlobal: 'darkShades',
  useTextColorGlobalDark: false,
  useTextColorGlobal: false,
  textColorDark: '#fff',
  textColor: '#181e21',

  textSubColorGlobalDark: 'darkShades',
  subTextColorGlobal: 'darkShades',
  useSubTextColorGlobalDark: false,
  useSubTextColorGlobal: false,
  textSubColorDark: '#7f8b92',
  subTextColor: '#7f8b92',

  animationBtnPray: 'b',
  titleFontSize: 14,
  /* ******tab****** */
  tabFontSize: '14',
  useGlobalTabColor: false,
  useGlobalTabColorDark: false,
  globalTabColor: 'lightShades',
  tabColor: '#808384',
  tabColorDark: '#808384',
  colorGlobal: 'lightShades',
  colorGlobalDark: 'lightShades',
  useGlobalTabActiveColor: false,
  useGlobalTabActiveColorDark: false,
  tabActiveColor: '#000',
  tabActiveColorDark: '#FFF',
  globalTabActiveColor: 'lightShades',
  globalTabActiveColorDark: 'lightShades',
  useGlobalTabBackground: false,
  useGlobalTabBackgroundDark: false,
  tabBackground: 'transparent',
  tabBackgroundDark: 'transparent',
  globalTabBackground: 'lightShades',
  globalTabBackgroundDark: 'lightShades',
  useGlobalTabActiveBackground: false,
  useGlobalTabActiveBackgroundDark: false,
  tabActiveBackground: 'transparent',
  tabActiveBackgroundDark: '#151718',
  globalTabActiveBackground: 'lightShades',
  globalTabActiveBackgroundDark: 'lightShades',
  useGlobalTabBorderColor: false,
  useGlobalTabBorderColorDark: false,
  tabBorderColor: 'transparent',
  tabBorderColorDark: 'transparent',
  globalTabBorderColor: 'lightShades',
  globalTabBorderColorDark: 'lightShades',
  useGlobalTabBorderColorActive: false,
  useGlobalTabBorderColorActiveDark: false,
  tabBorderColorActive: '#ececec',
  tabBorderColorActiveDark: '#26292C',
  globalTabBorderColorActive: 'lightShades',
  globalTabBorderColorActiveDark: 'lightShades',
  borderWidthTab: 1,
  /* ******tabView****** */
  tabView: false,
  useGlobalTabColorView: false,
  useGlobalTabColorDarkView: false,
  tabColorView: '#000',
  tabColorDarkView: '#FFF',
  colorGlobalView: 'lightShades',
  colorGlobalDarkView: 'lightShades',
  useGlobalTabActiveColorView: true,
  useGlobalTabActiveColorDarkView: true,
  tabActiveColorView: '#000',
  tabActiveColorDarkView: '#FFF',
  globalTabActiveColorView: 'lightShades',
  globalTabActiveColorDarkView: 'lightShades',
  useGlobalTabBackgroundView: false,
  useGlobalTabBackgroundDarkView: false,
  tabBackgroundView: 'transparent',
  tabBackgroundDarkView: 'transparent',
  globalTabBackgroundView: 'lightShades',
  globalTabBackgroundDarkView: 'lightShades',
  useGlobalTabActiveBackgroundView: false,
  useGlobalTabActiveBackgroundDarkView: false,
  tabActiveBackgroundView: 'transparent',
  tabActiveBackgroundDarkView: 'transparent',
  globalTabActiveBackgroundView: 'lightShades',
  globalTabActiveBackgroundDarkView: 'lightShades',
  useGlobalTabBorderColorView: false,
  useGlobalTabBorderColorDarkView: false,
  tabBorderColorView: 'transparent',
  tabBorderColorDarkView: 'transparent',
  globalTabBorderColorView: 'lightShades',
  globalTabBorderColorDarkView: 'lightShades',
  useGlobalTabBorderColorActiveView: false,
  useGlobalTabBorderColorActiveDarkView: false,
  tabBorderColorActiveView: '#808384',
  tabBorderColorActiveDarkView: '#26292C',
  globalTabBorderColorActiveView: 'lightShades',
  globalTabBorderColorActiveDarkView: 'lightShades',
  /* ******Filter****** */
  filter: true,
  filterFontSize: 14,
  colorFilter: '#000',
  colorFilterDark: '#FFF',
  colorFilterGlobal: 'lightShades',
  colorFilterGlobalDark: 'lightShades',
  useGlobalColorFilter: false,
  useGlobalColorFilterDark: false,
  useGlobalFilterBackground: false,
  useGlobalFilterBackgroundDark: false,
  filterBackground: '#FFF',
  filterBackgroundDark: '#191927',
  globalFilterBackground: 'lightShades',
  globalFilterBackgroundDark: 'lightShades',
  borderWidthFilter: 1,
  useGlobalFilterBorderColor: false,
  useGlobalFilterBorderColorDark: false,
  filterBorderColor: '#ececec',
  filterBorderColorDark: '#26292C',
  globalFilterBorderColor: 'lightShades',
  globalFilterBorderColorDark: 'lightShades',
  /* ******series****** */
  series: true,
  seriesFontSize: 16,
  seriesColor: '#000',
  seriesColorDark: '#FFF',
  seriesGlobal: 'lightShades',
  seriesGlobalDark: 'lightShades',
  useGlobalSeriesColor: false,
  useGlobalSeriesColorDark: false,
  seriesDescriptionColor: '#C0C0C0',
  seriesDescriptionColorDark: '#C0C0C0',
  seriesDescriptionGlobal: 'lightShades',
  seriesDescriptionGlobalDark: 'lightShades',
  useGlobalSeriesDescriptionColor: false,
  useGlobalSeriesDescriptionColorDark: false,
  useGlobalSeriesBackground: false,
  useGlobalSeriesBackgroundDark: false,
  seriesBackground: 'transparent',
  seriesBackgroundDark: 'transparent',
  globalSeriesBackground: 'lightShades',
  globalSeriesBackgroundDark: 'lightShades',
  useGlobalSeriesBorderColor: false,
  useGlobalSeriesBorderColorDark: false,
  seriesBorderColor: '#ececec',
  seriesBorderColorDark: '#ececec',
  globalSeriesBorderColor: 'lightShades',
  globalSeriesBorderColorDark: 'lightShades',
  borderWidthSeries: 1
})
const layoutSettings = (isCustomWidget) => {
  if (isCustomWidget) {
    return
  }
  return {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
    paddingRight: 15
  }
}
const widgetSettings = {
  roundImages: false,
  showCommentsBlogPost: true,
  sharingFacebook: true,
  sharingTwitter: true,
  sharingGoogle: true,
  sharingLinkedin: true,
  sharingEmail: true,
  fullImageBlogPost: false,
  widgetTitle: 'Latest Sermons',
  allLink: '/sermon',
  linkType: 'url',
  linkUrl: '/sermon',
  allLinkText: 'All Sermons',
  storeName: 'sermons',
  widgetIcon: 'sermon',
  fullImagePost: false,
  goNextPost: 'arrow',
  widgetDescription: false
}
const typographySettings = {
  fontFamily: FONT_FAMILIES_GLOBAL(),
  useFontFamilyCustom: true
}
const nightModeSettings = {
  autoDarkMode: true,
  startTime: new Date().setHours(19, 0),
  endTime: new Date().setHours(5, 0),
  isForceBackground: true
}

export const DONATION_PROMPT_DEFAULT_SETTINGS = {
  enabled: false,
  headerText: 'Thank You!',
  subheadingText: 'Please consider supporting our ministry financially',
  ctaPromo: 'Give, and it will be given to you',
  ctaText: 'We believe that generosity is one of the most important things in life. The generosity of our people allows us to share the hope of Christ and make a difference in our community. We also believe that God blesses those who are generous.',
  ctaActionType: 'url',
  ctaUrl: '',
  ctaEmbedCode: '',
  ctaColor: '',
  scripture: 'Give, and it will be given to you. A good measure, pressed down, shaken together and running over, will be poured into your lap. For with the measure you use, it will be measured to you. Luke 6:38 (NIV)',
  confirmButton: 'Give'
}

const settings = (isCustomWidget) => {
  return {
    nameSection: 'Prayer Wall Widget',
    [WIDGET_STYLE_SETTINGS]: widgetStyleSettings(isCustomWidget),
    [LAYOUT_SETTINGS]: layoutSettings(isCustomWidget)
  }
}
const elementSettings = {
  [SERMONS_WIDGET_SETTINGS]: widgetSettings,
  widgetType: 'PRAYER_WALL'
}

export const NEW_PRAYER_WALL_WIDGET = function (isCustomWidget) {
  return {
    id: getUniqueId(),
    type: TYPE_SECTION,
    sub: SUB_TYPE_PRAYER_WALL_WIDGET,
    resizable: false,
    isWebsiteOpenFirstTime: true,
    demoContentBuilder: true,
    demoSimulation: false,
    isNightSettings: false,
    analyticCode: '',
    displayMode: 'listView',
    [MODE_PC]: {
      isNightSettings: false,
      settings: {
        ...settings(isCustomWidget),
        [TYPOGRAPHY_SETTINGS]: isCustomWidget
          ? typographySettings
          : undefined,
        [NIGHT_MODE_SETTINGS]: isCustomWidget
          ? nightModeSettings
          : undefined
      },
      elementSettings
    },
    [MODE_TABLET]: {
      self: false,
      isNightSettings: false,
      settings: {
        ...settings(isCustomWidget),
        [TYPOGRAPHY_SETTINGS]: isCustomWidget
          ? typographySettings
          : undefined,
        [NIGHT_MODE_SETTINGS]: isCustomWidget
          ? nightModeSettings
          : undefined
      },
      elementSettings
    },
    [MODE_MOBILE]: {
      self: false,
      isNightSettings: false,
      settings: {
        ...settings(isCustomWidget),
        [TYPOGRAPHY_SETTINGS]: isCustomWidget
          ? typographySettings
          : undefined,
        [NIGHT_MODE_SETTINGS]: isCustomWidget
          ? nightModeSettings
          : undefined
      },
      elementSettings
    }
  }
}

export const DEFAULT_PRAYER_WALL_LAYOUT_SETTINGS = {
  marginTop: 0,
  marginBottom: 35,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0
}

export const animationSelector = [
  {
    key: 'a',
    text: 'First'
  },
  {
    key: 'b',
    text: 'Second'
  },
  {
    key: 'c',
    text: 'Third'
  },
  {
    key: 'd',
    text: 'Fourth'
  },
  {
    key: 'e',
    text: 'Fifth'
  },
  {
    key: 'f',
    text: 'Sixth'
  }
]

export const DONATION_ACTION_TYPES = [
  {
    key: 'url',
    text: 'Open URL'
  },
  {
    key: 'embed_code',
    text: 'Use Embed Code'
  }
]
