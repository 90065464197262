import * as types from '../mutations-types'
import { COUNTRIES_LIST } from '../../constants/countries'
import { BUTTON_LABELS } from '../../constants/widgetDefaultValues'
import { CAMPUS_OPTIONS } from '../../assets/constants/constant'
import { DONATION_PROMPT_DEFAULT_SETTINGS } from '@@@/preset/widgets/newPrayerWallWidget'

const state = {
  currentPrayerWall: {},
  globalColorsMain: {},
  globalColorsMainDark: {},
  requestsPrayers: [],
  reportsPrayers: [],
  campusesPrayers: [],
  myPostsPrayers: [],
  profileData: {},
  categoriesPrayers: [],
  queryStringRequestsPrayers: '',
  queryStringRequestsPrayersCategory: '',
  queryStringReportsPrayers: '',
  queryStringReportsPrayersCategory: '',
  queryStringMyPostsPrayers: '',
  queryStringMyPostsPrayersCategory: '',
  cheboxsRequestsPrayers: [],
  cheboxsReportsPrayers: [],
  cheboxsMyPostsPrayers: [],
  searchStringRequestsPrayers: '',
  searchStringReportsPrayers: '',
  searchStringMyPostsPrayers: '',
  currentPostPage: {},
  currentUser: {},
  loaderForGiveData: false,
  loaderForProfileQuery: false,
  tabProfileQueryCallback: null,
  abuseText: '',
  commentsCount: 0,
  delayPrayers: [],
  delayReports: [],
  delayPlay: false,
  localDarkMode: 'light',
  localExpandedPost: false,
  textRequest: '',
  textReport: '',
  textComment: '',
  textSubcomment: '',
  textInterfaceCategoryName: '',
  moderationStatus: '',
  moderationStopWords: [],
  generalSettingsSb: {
    countries: COUNTRIES_LIST
  },
  showChips: true,
  isForceBackground: true,
  allowUserToSharePosts: '',
  showEmergencyText: '',
  showComments: '',
  showDisclaimer: '',
  showNotifyOnReg: '',
  sendConfirmationTokenEmail: '',
  removeStepTwoReg: '',
  isActiveFilter: false,
  isActiveSort: false,
  postMaxChar: 750,
  postMinChar: 100,
  showModalLogin: false,
  showModalLogout: false,
  emergencyBody: '',
  privacyBody: '',
  showLastName: '',
  registrationOptions: [],
  registerForm: {},
  authTypeValue: null,
  selectedTab: 0,
  linkedRequestIdToReport: null,
  currentLocationData: null,
  cardColors: null,
  tabsNames: null,
  prayButtonLabels: null,
  buttonLabels: BUTTON_LABELS,
  serviceSettings: {},
  churchFinderQueryData: {
    filter: {
      country: [],
      state: []
    },
    search: ''
  },
  updatePostId: null,
  userTypesList: [],
  hideTypes: [],
  onLoginPrayCallback: null,
  churchFinderMarkersItems: [],
  campusOptions: CAMPUS_OPTIONS,
  donationPromptSettings: {}
}

const getters = {
  currentPrayerWall: state => state.currentPrayerWall,
  globalColorsMain: state => state.globalColorsMain,
  globalColorsMainDark: state => state.globalColorsMainDark,
  requestsPrayers: state => state.requestsPrayers,
  reportsPrayers: state => state.reportsPrayers,
  campusesPrayers: state => state.campusesPrayers,
  myPostsPrayers: state => state.myPostsPrayers,
  profileData: state => state.profileData,
  categoriesPrayers: state => state.categoriesPrayers,
  queryStringRequestsPrayers: state => state.queryStringRequestsPrayers,
  queryStringRequestsPrayersCategory: state => state.queryStringRequestsPrayersCategory,
  queryStringReportsPrayers: state => state.queryStringReportsPrayers,
  queryStringReportsPrayersCategory: state => state.queryStringReportsPrayersCategory,
  queryStringMyPostsPrayers: state => state.queryStringMyPostsPrayers,
  queryStringMyPostsPrayersCategory: state => state.queryStringMyPostsPrayersCategory,
  cheboxsRequestsPrayers: state => state.cheboxsRequestsPrayers,
  cheboxsReportsPrayers: state => state.cheboxsReportsPrayers,
  cheboxsMyPostsPrayers: state => state.cheboxsMyPostsPrayers,
  searchStringRequestsPrayers: state => state.searchStringRequestsPrayers,
  searchStringReportsPrayers: state => state.searchStringReportsPrayers,
  searchStringMyPostsPrayers: state => state.searchStringMyPostsPrayers,
  currentPostPage: state => state.currentPostPage,
  currentUser: state => state.currentUser,
  loaderForGiveData: state => state.loaderForGiveData,
  tabProfileQueryCallback: state => state.tabProfileQueryCallback,
  loaderForProfileQuery: state => state.loaderForProfileQuery,
  abuseText: state => state.abuseText,
  commentsCount: state => state.commentsCount,
  delayPrayers: state => state.delayPrayers,
  delayReports: state => state.delayReports,
  delayPlay: state => state.delayPlay,
  localDarkMode: state => state.localDarkMode,
  localExpandedPost: state => state.localExpandedPost,
  textRequest: state => state.textRequest,
  textReport: state => state.textReport,
  textComment: state => state.textComment,
  textSubcomment: state => state.textSubcomment,
  textInterfaceCategoryName: state => state.textInterfaceCategoryName,
  moderationStatus: state => state.moderationStatus,
  moderationStopWords: state => state.moderationStopWords,
  generalSettingsSb: state => state.generalSettingsSb,
  showChips: state => state.showChips,
  isForceBackground: state => state.isForceBackground,
  allowUserToSharePosts: state => state.allowUserToSharePosts,
  showEmergencyText: state => state.showEmergencyText,
  showDisclaimer: state => state.showDisclaimer,
  showNotifyOnReg: state => state.showNotifyOnReg,
  sendConfirmationTokenEmail: state => state.sendConfirmationTokenEmail,
  removeStepTwoReg: state => state.removeStepTwoReg,
  showComments: state => state.showComments,
  isActiveFilter: state => state.isActiveFilter,
  isActiveSort: state => state.isActiveSort,
  postMaxChar: state => state.postMaxChar,
  postMinChar: state => state.postMinChar,
  showModalLogin: state => state.showModalLogin,
  showModalLogout: state => state.showModalLogout,
  emergencyBody: state => state.emergencyBody,
  privacyBody: state => state.privacyBody,
  showLastName: state => state.showLastName,
  registrationOptions: state => state.registrationOptions,
  registerForm: state => state.registerForm,
  authTypeValue: state => state.authTypeValue,
  selectedTab: state => state.selectedTab,
  linkedRequestIdToReport: state => state.linkedRequestIdToReport,
  linkedRequestTopicToReport: state => state.linkedRequestTopicToReport,
  currentLocationData: state => state.currentLocationData,
  cardColors: state => state.cardColors,
  tabsNames: state => state.tabsNames,
  prayButtonLabels: state => state.prayButtonLabels,
  buttonLabels: state => state.buttonLabels,
  serviceSettings: state => state.serviceSettings,
  churchFinderQueryData: state => state.churchFinderQueryData,
  updatePostId: state => state.updatePostId,
  userTypesList: state => state.userTypesList,
  hideTypes: state => state.hideTypes,
  onLoginPrayCallback: state => state.onLoginPrayCallback,
  churchFinderMarkersItems: state => state.churchFinderMarkersItems,
  campusOptions: state => state.campusOptions,
  donationPromptSettings: state => {
    let settings = DONATION_PROMPT_DEFAULT_SETTINGS
    if (state.donationPromptSettings) {
      settings = {
        ...settings,
        ...state.donationPromptSettings
      }
    }
    return settings
  }
}

const actions = {
  setCurrentPrayerWall ({ commit }, data) {
    commit(types.PRAYER_WALL, data)
  },
  updateGlobalColorsMain ({ commit }, data) {
    commit(types.SET_GLOBAL_COLORS_MAIN, { data: data })
  },
  updateGlobalColorsMainDark ({ commit }, data) {
    commit(types.SET_GLOBAL_COLORS_MAIN_DARK, { data: data })
  },
  setRequestsPrayers ({ commit }, data) {
    commit(types.SET_REQUESTS, data)
  },
  setReportsPrayers ({ commit }, data) {
    commit(types.SET_REPORTS, data)
  },
  setCampusessPrayers ({ commit }, data) {
    commit(types.SET_CAMPUSES, data)
  },
  setMyPostsPrayers ({ commit }, data) {
    commit(types.SET_MY_POSTS, data)
  },
  setProfileDataPrayers ({ commit }, data) {
    commit(types.SET_PROFILE_DATA, data)
  },
  setCategoriesPrayers ({ commit }, data) {
    commit(types.SET_CATEGORIES, data)
  },
  setQueryStringRequestsPrayers ({ commit }, data) {
    commit(types.SET_QUERY_STRING_REQUESTS, data)
  },
  setQueryStringRequestsPrayersCategory ({ commit }, data) {
    commit(types.SET_QUERY_STRING_REQUESTS_CATEGORY, data)
  },
  setQueryStringReportsPrayers ({ commit }, data) {
    commit(types.SET_QUERY_STRING_REPORTS, data)
  },
  setQueryStringReportsPrayersCategory ({ commit }, data) {
    commit(types.SET_QUERY_STRING_REPORTS_CATEGORY, data)
  },
  setQueryStringMyPostsPrayers ({ commit }, data) {
    commit(types.SET_QUERY_STRING_MY_POSTS, data)
  },
  setQueryStringMyPostsPrayersCategory ({ commit }, data) {
    commit(types.SET_QUERY_STRING_MY_POSTS_CATEGORY, data)
  },
  setChexboxsRequestsPrayers ({ commit }, data) {
    commit(types.SET_CHECKBOXS_REQUESTS, data)
  },
  setChexboxsReportsPrayers ({ commit }, data) {
    commit(types.SET_CHECKBOXS_REPORTS, data)
  },
  setChexboxsMyPostsPrayers ({ commit }, data) {
    commit(types.SET_CHECKBOXS_MY_POSTS, data)
  },
  setSearchStringRequestsPrayers ({ commit }, data) {
    commit(types.SET_SEARCH_STRING_REQUESTS, data)
  },
  setSearchStringReportsPrayers ({ commit }, data) {
    commit(types.SET_SEARCH_STRING_REPORTS, data)
  },
  setSearchStringMyPostsPrayers ({ commit }, data) {
    commit(types.SET_SEARCH_STRING_MY_POSTS, data)
  },
  setCurrentPostPage ({ commit }, data) {
    commit(types.SET_CURRENT_POST_PAGE, data)
  },
  changeCurrentPostPage ({ commit }, data) {
    commit(types.CHANGE_CURRENT_POST_PAGE, data)
  },
  setCurrentUser ({ commit }, data) {
    commit(types.SET_CURRENT_USER, data)
  },
  setLikePrayer ({ commit }, data) {
    commit(types.SET_LIKE, data)
  },
  setDislikePrayer ({ commit }, data) {
    commit(types.SET_DISLIKE, data)
  },
  setAbusePrayer ({ commit }, data) {
    commit(types.SET_ABUSE, data)
  },
  setLikeComment ({ commit }, data) {
    commit(types.SET_LIKE_COMMENT, data)
  },
  setDislikeComment ({ commit }, data) {
    commit(types.SET_DISLIKE_COMMENT, data)
  },
  setAbuseComment ({ commit }, data) {
    commit(types.SET_ABUSE_COMMENT, data)
  },
  setGiveLoader ({ commit }, sost) {
    commit(types.SET_GIVE_LOADER, { sost: sost })
  },
  setLoaderForProfileQuery ({ commit }, sost) {
    commit(types.SET_PROFILE_QUERY_LOADER, { sost: sost })
  },
  setTabProfileQueryCallback ({ commit }, sost) {
    commit(types.SET_PROFILE_QUERY_CALLBACK, { sost: sost })
  },
  setAbuseText ({ commit }, data) {
    commit(types.SET_ABUSE_TEXT, data)
  },
  setCommentsCount ({ commit }, data) {
    commit(types.SET_COMMENTS_COUNT, data)
  },
  setDelayPrayers ({ commit }, data) {
    commit(types.SET_DELAY_REQUESTS_PRAYERS, data)
  },
  setDelayReports ({ commit }, data) {
    commit(types.SET_DELAY_REPORTS_PRAYERS, data)
  },
  setDelayPlay ({ commit }, data) {
    commit(types.SET_DELAY_PLAY, data)
  },
  setLocalDarkMode ({ commit }, data) {
    commit(types.SET_LOCAL_DARK_MODE, data)
  },
  setLocalExpandedPost ({ commit }, data) {
    commit(types.SET_LOCAL_EXPANDED_POST, data)
  },
  setTextRequest ({ commit }, data) {
    commit(types.SET_TEXT_REQUEST, data)
  },
  setTextReport ({ commit }, data) {
    commit(types.SET_TEXT_REPORT, data)
  },
  setTextComment ({ commit }, data) {
    commit(types.SET_TEXT_COMMENT, data)
  },
  setTextSubcomment ({ commit }, data) {
    commit(types.SET_TEXT_SUBCOMMENT, data)
  },
  setTextInterfaceCategoryName ({ commit }, data) {
    commit(types.SET_TEXT_INTERFACE_CATEGORY_NAME, data)
  },
  setModerationStatus ({ commit }, data) {
    commit(types.SET_MODERATION_STATUS, data)
  },
  setModerationStopWords ({ commit }, data) {
    commit(types.SET_MODERATION_STOP_WORDS, data)
  },
  setGeneralSettingsSb ({ commit }, data) {
    commit(types.SET_GENERAL_SETTINGS, data)
  },
  setShowChips ({ commit }, data) {
    commit(types.SET_SHOW_CHIPS, data)
  },
  setIsForceBackground ({ commit }, data) {
    commit(types.SET_FORCE_BACKGROUND, data)
  },
  setAllowUserToSharePosts ({ commit }, data) {
    commit(types.SET_ALLOW_USER_TO_SHARE_POSTS, data)
  },
  setShowEmergencyText ({ commit }, data) {
    commit(types.SET_SHOW_EMERGENCY_TEXT, data)
  },
  setShowComments ({ commit }, data) {
    commit(types.SET_SHOW_COMMENTS, data)
  },
  setShowDisclaimer ({ commit }, data) {
    commit(types.SET_SHOW_DISCLAIMER, data)
  },
  setShowNotifyOnReg ({ commit }, data) {
    commit(types.SET_SHOW_NOTIFY_ON_REG, data)
  },
  setSendConfirmationTokenEmail ({ commit }, data) {
    commit(types.SET_SEND_CONFIRMATION_TOKEN_EMAIL, data)
  },
  setRemoveStepTwoReg ({ commit }, data) {
    commit(types.SET_REMOVE_STEP_TWO, data)
  },
  setIsActiveFilter ({ commit }, data) {
    commit(types.SET_IS_ACTIVE_FILTER, data)
  },
  setIsActiveSort ({ commit }, data) {
    commit(types.SET_IS_ACTIVE_SORT, data)
  },
  setPostMaxChar ({ commit }, data) {
    commit(types.SET_POST_MAX_CHAR, data)
  },
  setPostMinChar ({ commit }, data) {
    commit(types.SET_POST_MIN_CHAR, data)
  },
  setShowModalLogin ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_LOGIN, data)
  },
  setShowModalLogout ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_LOGOUT, data)
  },
  setEmergencyBody ({ commit }, data) {
    commit(types.SET_EMERGENCY_BODY, data)
  },
  setPrivacyBody ({ commit }, data) {
    commit(types.SET_PRIVACY_BODY, data)
  },
  setShowLastName ({ commit }, data) {
    commit(types.SET_SHOW_LAST_NAME, data)
  },
  setRegistrationOptions ({ commit }, data) {
    commit(types.SET_REGISTRATION_OPTIONS, data)
  },
  setRegisterForm ({ commit }, data) {
    commit(types.SET_REGISTER_FORM, data)
  },
  setAuthTypeValue ({ commit }, data) {
    commit(types.SET_AUTH_TYPE_VALUE, data)
  },
  setLinkedRequestIdToReport ({ commit }, data) {
    commit(types.SET_LINKED_REQUEST_ID_TO_REPORT, data)
  },
  setLinkedRequestTopicToReport ({ commit }, data) {
    commit(types.SET_LINKED_CATEGORY_TO_REPORT, data)
  },
  setSelectedTab ({ commit }, data) {
    commit(types.SET_SELECTED_TAB, data)
  },
  setCurrentLocationData ({ commit }, data) {
    commit(types.SET_CURRENT_LOCATION_DATA, data)
  },
  setCardColors ({ commit }, data) {
    commit(types.SET_CARD_COLORS, data)
  },
  setTabsNames ({ commit }, data) {
    commit(types.SET_TABS_NAMES, data)
  },
  setPrayButtonLabels ({ commit }, data) {
    commit(types.SET_PRAY_BUTTON_LABELS, data)
  },
  setButtonLabels ({ commit }, data) {
    commit(types.SET_BUTTON_LABELS, data)
  },
  setServiceSettings ({ commit }, data) {
    commit(types.SET_SERVICE_SETTINGS, data)
  },
  setChurchFinderQueryData ({ commit }, data) {
    commit(types.SET_CHURCH_FINDER_QUERY_DATA, data)
  },
  setUpdatePostId ({ commit }, data) {
    commit(types.SET_UPDATE_POST_ID, data)
  },
  setUserTypesList ({ commit }, data) {
    commit(types.UPDATE_USER_TYPES_LIST, data)
  },
  setHideTypes ({ commit }, data) {
    commit(types.UPDATE_USER_HIDE_TYPES, data)
  },
  setPrayLoginCallback ({ commit }, data) {
    commit(types.UPDATE_PRAY_LOGIN_CALLBACK, data)
  },
  setChurchFinderMarkersItems ({ commit }, data) {
    commit(types.UPDATE_CHURCH_FINDER_MARKERS_ITEMS, data)
  },
  setCampusOptions ({ commit }, data) {
    commit(types.SET_CAMPUS_OPTIONS, data)
  },
  setDonationPromptSettings ({ commit }, data) {
    commit(types.SET_DONATION_PROMPT_SETTINGS, data)
  },
  deletePrayer ({ commit }, data) {
    commit(types.DELETE_PRAYER, data)
  }
}
const mutations = {
  [types.PRAYER_WALL] (state, data) {
    state.currentPrayerWall = data
  },
  [types.SET_GLOBAL_COLORS_MAIN] (state, { data }) {
    state.globalColorsMain = data
  },
  [types.SET_GLOBAL_COLORS_MAIN_DARK] (state, { data }) {
    state.globalColorsMainDark = data
  },
  [types.SET_REQUESTS] (state, data) {
    state.requestsPrayers = data
  },
  [types.SET_REPORTS] (state, data) {
    state.reportsPrayers = data
  },
  [types.SET_CAMPUSES] (state, data) {
    state.campusesPrayers = data
  },
  [types.SET_MY_POSTS] (state, data) {
    state.myPostsPrayers = data
  },
  [types.SET_PROFILE_DATA] (state, data) {
    state.profileData = data
  },
  [types.SET_CATEGORIES] (state, data) {
    state.categoriesPrayers = data
  },
  [types.SET_QUERY_STRING_REQUESTS] (state, data) {
    state.queryStringRequestsPrayers = data
  },
  [types.SET_QUERY_STRING_REQUESTS_CATEGORY] (state, data) {
    state.queryStringRequestsPrayersCategory = data
  },
  [types.SET_QUERY_STRING_REPORTS] (state, data) {
    state.queryStringReportsPrayers = data
  },
  [types.SET_QUERY_STRING_REPORTS_CATEGORY] (state, data) {
    state.queryStringReportsPrayersCategory = data
  },
  [types.SET_QUERY_STRING_MY_POSTS] (state, data) {
    state.queryStringMyPostsPrayers = data
  },
  [types.SET_QUERY_STRING_MY_POSTS_CATEGORY] (state, data) {
    state.queryStringMyPostsPrayersCategory = data
  },
  [types.SET_CHECKBOXS_REQUESTS] (state, data) {
    state.cheboxsRequestsPrayers = data
  },
  [types.SET_CHECKBOXS_REPORTS] (state, data) {
    state.cheboxsReportsPrayers = data
  },
  [types.SET_CHECKBOXS_MY_POSTS] (state, data) {
    state.cheboxsMyPostsPrayers = data
  },
  [types.SET_SEARCH_STRING_REQUESTS] (state, data) {
    state.searchStringRequestsPrayers = data
  },
  [types.SET_SEARCH_STRING_REPORTS] (state, data) {
    state.searchStringReportsPrayers = data
  },
  [types.SET_SEARCH_STRING_MY_POSTS] (state, data) {
    state.searchStringMyPostsPrayers = data
  },
  [types.SET_CURRENT_POST_PAGE] (state, data) {
    state.currentPostPage = data
    // console.log('state.currentPostPage', state.currentPostPage)
  },
  [types.CHANGE_CURRENT_POST_PAGE] (state, data) {
    const { action } = data
    if (Object.keys(state.currentPostPage).length) {
      if (action === 'like') {
        state.currentPostPage = { ...state.currentPostPage, liked: true, prayers: state.currentPostPage.prayers + 1 }
      } else if (action === 'dislike') {
        state.currentPostPage = { ...state.currentPostPage, liked: false, prayers: state.currentPostPage.prayers - 1 }
      }
    }
  },
  [types.SET_CURRENT_USER] (state, data) {
    state.currentUser = data
  },
  [types.SET_LIKE] (state, { type, id }) {
    if (type === 1) {
      const index = state.requestsPrayers.findIndex((item) => id === item.id)
      const obj = state.requestsPrayers.find((item) => id === item.id)
      state.requestsPrayers.splice(index, 1, { ...obj, liked: true, prayers: obj.prayers + 1 })
    }
    if (type === 3) {
      const index = state.myPostsPrayers.findIndex((item) => id === item.id)
      const obj = state.myPostsPrayers.find((item) => id === item.id)
      state.myPostsPrayers.splice(index, 1, { ...obj, liked: true, prayers: obj.prayers + 1 })
    }
  },
  [types.SET_DISLIKE] (state, { type, id }) {
    if (type === 1) {
      const index = state.requestsPrayers.findIndex((item) => id === item.id)
      const obj = state.requestsPrayers.find((item) => id === item.id)
      state.requestsPrayers.splice(index, 1, { ...obj, liked: false, prayers: obj.prayers - 1 })
    }
    if (type === 3) {
      const index = state.myPostsPrayers.findIndex((item) => id === item.id)
      const obj = state.myPostsPrayers.find((item) => id === item.id)
      state.myPostsPrayers.splice(index, 1, { ...obj, liked: false, prayers: obj.prayers - 1 })
    }
  },
  [types.SET_ABUSE] (state, { type, id }) {
    if (type === 1) {
      const index = state.requestsPrayers.findIndex((item) => id === item.id)
      const obj = state.requestsPrayers.find((item) => id === item.id)
      state.requestsPrayers.splice(index, 1, { ...obj, abused: true })
    }
    if (type === 2) {
      const index = state.reportsPrayers.findIndex((item) => id === item.id)
      const obj = state.reportsPrayers.find((item) => id === item.id)
      state.reportsPrayers.splice(index, 1, { ...obj, abused: true })
    }
    if (type === 3) {
      const index = state.myPostsPrayers.findIndex((item) => id === item.id)
      const obj = state.myPostsPrayers.find((item) => id === item.id)
      state.myPostsPrayers.splice(index, 1, { ...obj, abused: true })
    }
  },
  [types.SET_LIKE_COMMENT] (state, { type, id, idCard }) {
    if (type === 1) {
      const indexCard = state.requestsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.requestsPrayers.find((item) => idCard === item.id)
      state.requestsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: true, likes: item.likes + 1 })) })
    }
    if (type === 2) {
      const indexCard = state.reportsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.reportsPrayers.find((item) => idCard === item.id)
      state.reportsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: true, likes: item.likes + 1 })) })
    }
    if (type === 3) {
      const indexCard = state.myPostsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.myPostsPrayers.find((item) => idCard === item.id)
      state.myPostsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: true, likes: item.likes + 1 })) })
    }
  },
  [types.SET_DISLIKE_COMMENT] (state, { type, id, idCard }) {
    if (type === 1) {
      const indexCard = state.requestsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.requestsPrayers.find((item) => idCard === item.id)
      state.requestsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: false, likes: item.likes - 1 })) })
    }
    if (type === 2) {
      const indexCard = state.reportsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.reportsPrayers.find((item) => idCard === item.id)
      state.reportsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: false, likes: item.likes - 1 })) })
    }
    if (type === 3) {
      const indexCard = state.myPostsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.myPostsPrayers.find((item) => idCard === item.id)
      state.myPostsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, liked: false, likes: item.likes - 1 })) })
    }
  },
  [types.SET_ABUSE_COMMENT] (state, { type, id, idCard }) {
    if (type === 1) {
      const indexCard = state.requestsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.requestsPrayers.find((item) => idCard === item.id)
      state.requestsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, abused: item.id === id ? true : item.abused })) })
    }
    if (type === 2) {
      const indexCard = state.reportsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.reportsPrayers.find((item) => idCard === item.id)
      state.reportsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, abused: item.id === id ? true : item.abused })) })
    }
    if (type === 3) {
      const indexCard = state.myPostsPrayers.findIndex((item) => idCard === item.id)
      const objCard = state.myPostsPrayers.find((item) => idCard === item.id)
      state.myPostsPrayers.splice(indexCard, 1, { ...objCard, commentList: objCard.commentList.map((item) => ({ ...item, abused: item.id === id ? true : item.abused })) })
    }
  },
  [types.SET_GIVE_LOADER] (state, { sost }) {
    state.loaderForGiveData = sost
  },
  [types.SET_PROFILE_QUERY_LOADER] (state, { sost }) {
    state.loaderForProfileQuery = sost
  },
  [types.SET_PROFILE_QUERY_CALLBACK] (state, { sost }) {
    state.tabProfileQueryCallback = sost
  },
  [types.SET_ABUSE_TEXT] (state, data) {
    state.abuseText = data
  },
  [types.SET_COMMENTS_COUNT] (state, data) {
    state.commentsCount = data
  },
  [types.SET_DELAY_REQUESTS_PRAYERS] (state, data) {
    state.delayPrayers = data
  },
  [types.SET_DELAY_REPORTS_PRAYERS] (state, data) {
    state.delayReports = data
  },
  [types.SET_DELAY_PLAY] (state, data) {
    state.delayPlay = data
  },
  [types.SET_LOCAL_DARK_MODE] (state, data) {
    state.localDarkMode = data
  },
  [types.SET_LOCAL_EXPANDED_POST] (state, data) {
    state.localExpandedPost = data
  },
  [types.SET_TEXT_REQUEST] (state, data) {
    state.textRequest = data
  },
  [types.SET_TEXT_REPORT] (state, data) {
    state.textReport = data
  },
  [types.SET_TEXT_COMMENT] (state, data) {
    state.textComment = data
  },
  [types.SET_TEXT_SUBCOMMENT] (state, data) {
    state.textSubcomment = data
  },
  [types.SET_TEXT_INTERFACE_CATEGORY_NAME] (state, data) {
    state.textInterfaceCategoryName = data
  },
  [types.SET_MODERATION_STATUS] (state, data) {
    state.moderationStatus = data
  },
  [types.SET_MODERATION_STOP_WORDS] (state, data) {
    if (typeof data === 'string') {
      state.moderationStopWords = data.toLowerCase().split(',').map(item => item.trim())
    } else {
      state.moderationStopWords = data
    }
  },
  [types.SET_GENERAL_SETTINGS] (state, data) {
    state.generalSettingsSb = data
  },
  [types.SET_SHOW_CHIPS] (state, data) {
    state.showChips = data
  },
  [types.SET_FORCE_BACKGROUND] (state, data) {
    state.isForceBackground = data
  },
  [types.SET_ALLOW_USER_TO_SHARE_POSTS] (state, data) {
    state.allowUserToSharePosts = data
  },
  [types.SET_SHOW_EMERGENCY_TEXT] (state, data) {
    state.showEmergencyText = data
  },
  [types.SET_SHOW_COMMENTS] (state, data) {
    state.showComments = data
  },
  [types.SET_SHOW_DISCLAIMER] (state, data) {
    state.showDisclaimer = data
  },
  [types.SET_SHOW_NOTIFY_ON_REG] (state, data) {
    state.showNotifyOnReg = data
  },
  [types.SET_SEND_CONFIRMATION_TOKEN_EMAIL] (state, data) {
    state.sendConfirmationTokenEmail = data
  },
  [types.SET_REMOVE_STEP_TWO] (state, data) {
    state.removeStepTwoReg = data
  },
  [types.SET_IS_ACTIVE_FILTER] (state, data) {
    state.isActiveFilter = data
  },
  [types.SET_IS_ACTIVE_SORT] (state, data) {
    state.isActiveSort = data
  },
  [types.SET_POST_MAX_CHAR] (state, data) {
    state.postMaxChar = data
  },
  [types.SET_POST_MIN_CHAR] (state, data) {
    state.postMinChar = data
  },
  [types.SET_SHOW_MODAL_LOGIN] (state, data) {
    state.showModalLogin = data
  },
  [types.SET_SHOW_MODAL_LOGOUT] (state, data) {
    state.showModalLogout = data
  },
  [types.SET_EMERGENCY_BODY] (state, data) {
    state.emergencyBody = data
  },
  [types.SET_PRIVACY_BODY] (state, data) {
    state.privacyBody = data
  },
  [types.SET_SHOW_LAST_NAME] (state, data) {
    state.showLastName = data
  },
  [types.SET_REGISTRATION_OPTIONS] (state, data) {
    state.registrationOptions = data
  },
  [types.SET_REGISTER_FORM] (state, data) {
    state.registerForm = data
  },
  [types.SET_AUTH_TYPE_VALUE] (state, data) {
    state.authTypeValue = data
  },
  [types.SET_SELECTED_TAB] (state, data) {
    state.selectedTab = data
  },
  [types.SET_LINKED_REQUEST_ID_TO_REPORT] (state, data) {
    state.linkedRequestIdToReport = data
  },
  [types.SET_LINKED_CATEGORY_TO_REPORT] (state, data) {
    state.linkedRequestTopicToReport = data
  },
  [types.SET_CURRENT_LOCATION_DATA] (state, data) {
    state.currentLocationData = data
  },
  [types.SET_CARD_COLORS] (state, data) {
    state.cardColors = data
  },
  [types.SET_TABS_NAMES] (state, data) {
    state.tabsNames = data
  },
  [types.SET_PRAY_BUTTON_LABELS] (state, data) {
    state.prayButtonLabels = data
  },
  [types.SET_BUTTON_LABELS] (state, data) {
    state.buttonLabels = data
  },
  [types.SET_SERVICE_SETTINGS] (state, data) {
    state.serviceSettings = data
  },
  [types.SET_CHURCH_FINDER_QUERY_DATA] (state, data) {
    state.churchFinderQueryData = data
  },
  [types.SET_UPDATE_POST_ID] (state, data) {
    state.updatePostId = data
  },
  [types.UPDATE_USER_TYPES_LIST] (state, data) {
    state.userTypesList = data
  },
  [types.UPDATE_USER_HIDE_TYPES] (state, data) {
    state.hideTypes = data
  },
  [types.UPDATE_PRAY_LOGIN_CALLBACK] (state, data) {
    state.onLoginPrayCallback = data
  },
  [types.UPDATE_CHURCH_FINDER_MARKERS_ITEMS] (state, data) {
    state.churchFinderMarkersItems = data
  },
  [types.SET_CAMPUS_OPTIONS] (state, data) {
    state.campusOptions = data
  },
  [types.SET_DONATION_PROMPT_SETTINGS] (state, data) {
    state.donationPromptSettings = data
  },
  [types.DELETE_PRAYER] (state, data) {
    state.requestsPrayers = state.requestsPrayers.filter(({ id }) => id !== data)
    state.reportsPrayers = state.reportsPrayers.filter(({ id }) => id !== data)
    state.myPostsPrayers = state.myPostsPrayers.filter(({ id }) => id !== data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
