import * as subType from '@@@/preset/elements/listSubTypes'
export const TYPE_SECTION = 'TYPE_SECTION'
export const TYPE_ROW = 'TYPE_ROW'
export const TYPE_COLUMN = 'TYPE_COLUMN'
export const TYPE_ELEMENT = 'TYPE_ELEMENT'

export const SECTION = {
  name: 'Section',
  icon: 'section',
  type: TYPE_SECTION
}

export const ROW = {
  name: 'Row',
  icon: 'row',
  type: TYPE_ROW
}

export const COLUMN = {
  name: 'Column',
  icon: 'column',
  type: TYPE_COLUMN
}

export const HEADLINE = {
  name: 'Heading',
  icon: 'heading',
  type: TYPE_ELEMENT,
  view: 'font',
  sub: subType.SUB_TYPE_HEADLINE
}

export const SUBHEADLINE = {
  name: 'Subheading',
  icon: 'subheading',
  type: TYPE_ELEMENT,
  view: 'font',
  sub: subType.SUB_TYPE_SUBHEADLINE
}

export const TEXT_BLOCK = {
  name: 'Text Block',
  icon: 'textblock',
  type: TYPE_ELEMENT,
  view: 'font',
  sub: subType.SUB_TYPE_TEXT_BLOCK
}

export const BUTTON = {
  name: 'Button',
  icon: 'button',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUTTON
}

export const QR = {
  name: 'QR',
  icon: 'button',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_QR
}

export const IFRAME = {
  name: 'Iframe',
  icon: 'div',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_IFRAME
}

export const IMAGE = {
  name: 'Image',
  icon: 'image',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_IMAGE
}

export const ICON = {
  name: 'Icon',
  icon: 'image',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ICON
}

export const COUNTER = {
  name: 'Counter',
  icon: 'image',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_COUNTER
}

export const VIDEO = {
  name: 'Video',
  icon: 'video',
  type: TYPE_ELEMENT,
  preview: 'video',
  sub: subType.SUB_TYPE_VIDEO
}

export const DIVIDER = {
  name: 'Divider',
  icon: 'divider',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DIVIDER,
  preview: 'dividerMiniHorizontal'
}

export const DIVIDER_VERTICAL = {
  name: 'Divider',
  icon: 'divider',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DIVIDER_VERTICAL,
  preview: 'dividerMiniVertical'
}

export const SOCIAL = {
  name: 'Social',
  icon: 'social',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SOCIAL_PANEL
}

export const NEW_DESIGN_SOCIAL = {
  name: 'Social',
  icon: 'social',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_NEW_DESIGN_SOCIAL_PANEL
}

export const SLIDER = {
  name: 'Slider',
  icon: 'slider',
  type: TYPE_SECTION,
  preview: 'slider',
  sub: subType.SUB_TYPE_SLIDER
}

export const MENU_LIST = {
  name: 'Navigation',
  icon: 'navigation',
  type: TYPE_SECTION,
  preview: 'nav',
  sub: subType.SUB_TYPE_MENU_LIST
}

export const MENU_LIST_1 = {
  name: 'Navigation #1',
  icon: 'navigation',
  type: TYPE_SECTION,
  preview: 'nav1',
  settingsType: 1,
  sub: subType.SUB_TYPE_MENU_LIST_1
}
export const MENU_LIST_PULL_OUT = {
  name: 'Navigation #12',
  icon: 'navigation',
  type: TYPE_SECTION,
  beforeMount () {
    console.log('item', this.elementsList)
  },
  preview: 'nav1',
  settingsType: 1,
  sub: subType.SUB_TYPE_MENU_PULL_OUT
}

export const MENU_LIST_8 = {
  name: 'Navigation #8',
  icon: 'navigation',
  preview: 'nav8',
  type: TYPE_SECTION,
  settingsType: 2,
  sub: subType.SUB_TYPE_MENU_LIST_1
}

export const MENU_LIST_9 = {
  name: 'Navigation #9',
  icon: 'navigation',
  preview: 'nav9',
  type: TYPE_SECTION,
  settingsType: 3,
  sub: subType.SUB_TYPE_MENU_LIST_1
}

export const MENU_LIST_12 = {
  name: 'Navigation #12',
  icon: 'navigation',
  preview: 'nav12',
  type: TYPE_SECTION,
  settingsType: 4,
  sub: subType.SUB_TYPE_MENU_LIST_1
}

export const MENU_LIST_13 = {
  name: 'Navigation #13',
  icon: 'navigation',
  preview: 'nav13',
  type: TYPE_SECTION,
  settingsType: 5,
  sub: subType.SUB_TYPE_MENU_LIST_1
}

export const MENU_LIST_14 = {
  name: 'Navigation #14',
  icon: 'navigation',
  preview: 'nav14',
  type: TYPE_SECTION,
  settingsType: 6,
  sub: subType.SUB_TYPE_MENU_LIST_1
}

export const MENU_LIST_2 = {
  name: 'Navigation #2',
  icon: 'navigation',
  type: TYPE_SECTION,
  preview: 'nav2',
  sub: subType.SUB_TYPE_MENU_LIST_2
}

export const MENU_LIST_3 = {
  name: 'Navigation #3',
  icon: 'navigation',
  type: TYPE_SECTION,
  preview: 'nav3',
  sub: subType.SUB_TYPE_MENU_LIST_3
}

export const MENU_LIST_4 = {
  name: 'Navigation #4',
  icon: 'navigation',
  preview: 'nav4',
  type: TYPE_SECTION,
  settingsType: 1,
  sub: subType.SUB_TYPE_MENU_LIST_4
}

export const MENU_LIST_10 = {
  name: 'Navigation #10',
  icon: 'navigation',
  preview: 'nav10',
  type: TYPE_SECTION,
  settingsType: 2,
  sub: subType.SUB_TYPE_MENU_LIST_4
}

export const MENU_LIST_5 = {
  name: 'Navigation #5',
  icon: 'navigation',
  preview: 'nav5',
  type: TYPE_SECTION,
  settingsType: 1,
  sub: subType.SUB_TYPE_MENU_LIST_5
}

export const MENU_LIST_11 = {
  name: 'Navigation #11',
  icon: 'navigation',
  preview: 'nav11',
  type: TYPE_SECTION,
  settingsType: 2,
  sub: subType.SUB_TYPE_MENU_LIST_5
}

export const MENU_LIST_6 = {
  name: 'Navigation #6',
  icon: 'navigation',
  preview: 'nav6',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MENU_LIST_6
}

export const MENU_LIST_7 = {
  name: 'Navigation #7',
  icon: 'navigation',
  preview: 'nav7',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MENU_LIST_7
}

export const FOOTER = {
  name: 'Footer',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER,
  preview: 'footer1'
}

export const FOOTER_SMALL = {
  name: 'Footer Small',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_SMALL,
  preview: 'footer3'
}

export const FOOTER_TWO = {
  name: 'Footer Two',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_TWO,
  preview: 'footer2'
}

export const FOOTER_FOUR = {
  name: 'Footer Four',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_FOUR,
  preview: 'socialPrefooter'
}

export const FOOTER_FIVE = {
  name: 'Footer Five',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_FIVE,
  preview: 'socialPrefooter'
}

export const FOOTER_SIX = {
  name: 'Footer #Six',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_SIX,
  preview: 'footerSix'
}

export const FOOTER_SEVEN = {
  name: 'Footer #Seven',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_SEVEN,
  preview: 'footerSeven'
}

export const FOOTER_THREE = {
  name: 'Footer Three',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_THREE,
  preview: 'footerNew'
}

export const FOOTER_ONE = {
  name: 'Footer One',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_ONE,
  preview: 'footer4'
}

export const FOOTER_EIGHT = {
  name: 'Footer #Eight',
  icon: 'section',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_FOOTER_EIGHT,
  preview: 'footerEight'
}
export const TABLE = {
  name: 'Grid',
  icon: 'grid',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TABLE
}

export const DIRECTIONS_ICON = {
  name: 'Directions',
  icon: 'directions',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DIRECTIONS_ICON,
  preview: 'directions'
}

export const MISSION = {
  name: 'Mission',
  icon: 'mission',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MISSION,
  preview: 'directions'
}

export const VIDEO_FULL = {
  name: 'Video',
  icon: 'mission',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_VIDEO_FULL,
  preview: 'videoFull'
}

export const MINISTRY = {
  name: 'Ministry',
  icon: 'ministry',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MINISTRY,
  preview: 'ministriesMiniHorizontal'
}

export const MINISTRY2 = {
  name: 'Ministry #2',
  icon: 'ministry',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MINISTRY2,
  preview: 'ministriesMiniHorizontal'
}

export const EVENTS_WIDGET = {
  name: 'Events',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_WIDGET,
  preview: 'widgets_events_new',
  route: 'events'
}

export const EVENTS_WIDGET_COMPACT = {
  name: 'Events',
  icon: 'events',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_EVENTS_WIDGET_COMPACT,
  preview: 'mini-widgets-events'
}

export const BLOG_WIDGET = {
  name: 'Blog',
  icon: 'blog',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_BLOG_WIDGET,
  preview: 'widgets_blog-news'
}

export const BLOG_WIDGET_COMPACT = {
  name: 'Blog',
  icon: 'blog',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BLOG_COMPACT_WIDGET,
  preview: 'blogsMini'
}

export const NEWS_WIDGET = {
  name: 'News',
  icon: 'news',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_NEWS_WIDGET,
  preview: 'widgets_blog-news'
}

export const NEWS_WIDGET_COMPACT = {
  name: 'News',
  icon: 'news',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_NEWS_COMPACT_WIDGET,
  preview: 'newsMini'
}

export const DEVOTIONS_WIDGET = {
  name: 'Devotions',
  icon: 'devotions',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_DEVOTIONS_WIDGET,
  preview: 'widgets_devotions'
}

export const DEVOTIONS_COMPACT = {
  name: 'Devotions',
  icon: 'devotions',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DEVOTIONS_COMPACT_WIDGET,
  preview: 'devotionsMini'
}

export const READING_PLAN_WIDGET = {
  name: 'Reading Plan ',
  icon: 'reading-plan',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_READING_PLAN_WIDGET,
  preview: 'widgets_reading-plan'
}

export const READING_PLAN_COMPACT_WIDGET = {
  name: 'Reading Plan',
  icon: 'reading-plan',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_READING_PLAN_COMPACT_WIDGET,
  preview: 'readingPlanMini'
}

export const PHOTO_WIDGET = {
  name: 'Photo ',
  icon: 'photo',
  type: TYPE_SECTION,
  preview: 'widgets_photo',
  sub: subType.SUB_TYPE_PHOTO_WIDGET
}

export const PHOTO_COMPACT_WIDGET = {
  name: 'Photo',
  icon: 'photo',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PHOTO_COMPACT_WIDGET,
  preview: 'photoMini'
}

export const CONTACT_US_WIDGET = {
  name: 'Contact Us',
  icon: 'contact-us',
  type: TYPE_SECTION,
  preview: 'widgets_contactUs',
  sub: subType.SUB_TYPE_CONTACT_US_WIDGET
}

export const ABOUT_US_WIDGET = {
  name: 'About Us',
  icon: 'about-us',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_ABOUT_US_WIDGET,
  preview: 'aboutUs'
}

export const MINISTRIES_WIDGET = {
  name: 'Ministries',
  icon: 'ministries',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MINISTRIES_WIDGET,
  preview: 'ministries'
}

export const SERMONS_WIDGET = {
  name: 'Sermons',
  icon: 'sermons',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_SERMONS_WIDGET,
  preview: 'sermons'
}

export const LIVE_NEW_WIDGET = {
  name: 'Live',
  icon: 'liveStream',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LIVE_NEW_WIDGET,
  preview: 'livewidget2'
}

export const SERMONS_WIDGET2 = {
  name: 'Sermons2',
  icon: 'sermons',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_SERMONS_WIDGET2,
  preview: 'latestSermont'
}

export const LIVE_STREAMING_WIDGET = {
  name: 'Live Streaming',
  icon: 'live',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LIVE_STREAMING_WIDGET,
  preview: 'liveStream'
}

export const LIVE_STREAMING_WIDGET2 = {
  name: 'Live Streaming 2',
  icon: 'live',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LIVE_STREAMING_WIDGET2,
  preview: 'live3'
}

export const STAFF_WIDGET = {
  name: 'Staff',
  icon: 'staff',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_STAFF_WIDGET,
  preview: 'widgets_staff'
}

export const ACCORDION = {
  name: 'Accordion',
  icon: 'accordion',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ACCORDION
}

export const VISITOR_WIDGET = {
  name: 'New Visitor',
  icon: 'new_visitor',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_VISITOR_WIDGET,
  preview: 'newVisitorMini'
}
export const CLOCK_WIDGET = {
  name: 'Clock',
  icon: 'counter',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_CLOCK_WIDGET,
  preview: 'counter'
}

export const WATCH_US_ON_TV_WIDGET = {
  name: 'New Watch Us On TV',
  icon: 'new_visitor',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WATCH_US_ON_TV_WIDGET,
  preview: 'newWatchUsMini'
}

export const SUBSCRIBE_WIDGET = {
  name: 'New Subscribe',
  icon: 'subscribe',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SUBSCRIBE_WIDGET,
  preview: 'newSubscribeMini'
}

export const WELCOME_WIDGET = {
  name: 'Welcome',
  icon: 'photo',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WELCOME_WIDGET,
  preview: 'welcomeMini'
}

export const MOBILE_APP_WIDGET = {
  name: 'Mobile App',
  icon: 'app_promo',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_MOBILE_APP_WIDGET,
  preview: 'mobileAppMini'
}

export const TV_APP_WIDGET = {
  name: 'TV App',
  icon: 'tv',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TV_APP_WIDGET,
  preview: 'tvAppMini'
}

export const APP_PAGE_WIDGET = {
  name: 'App Page',
  icon: 'tv',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_APP_PAGE_WIDGET,
  preview: 'appPage'
}

export const GIVE_WIDGET = {
  name: 'Give',
  icon: 'give',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_GIVE_WIDGET,
  preview: 'giveMini'
}

export const STREAM_WIDGET = {
  name: 'Live Stream',
  icon: 'live',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_STREAM_WIDGET,
  preview: 'simulatedLive'
}

export const SERMONS_WIDGET_COMPACT = {
  name: 'Sermons',
  icon: 'sermons',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SERMONS_COMPACT_WIDGET,
  preview: 'sermonsMini'
}

export const LATEST_WIDGET_COMPACT = {
  name: 'Latest',
  icon: 'sermons',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LATEST_COMPACT_WIDGET,
  preview: 'sermonsMini',
  widgetType: 1
}

export const LATEST_WIDGET_COMPACT2 = {
  name: 'Latest',
  icon: 'sermons',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LATEST_COMPACT_WIDGET,
  preview: 'latestSermon2',
  widgetType: 2
}

export const PRIVACY_POLICY_WIDGET = {
  name: 'New Privacy Policy',
  icon: 'new_visitor',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_PRIVACY_POLICY_WIDGET,
  preview: 'newPrivacyPolicy'
}

export const DROPDOWN = {
  name: 'Dropdown',
  icon: 'dropdown',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DROPDOWN
}

export const NEWS_WIDGET_COMPACT_HORIZONTAL = {
  name: 'News (horizontal)',
  icon: 'news',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_NEWS_COMPACT_HORIZONTAL_WIDGET,
  preview: 'newsMiniHorizontal'
}

export const BLOGS_WIDGET_COMPACT_HORIZONTAL = {
  name: 'Blogs (horizontal)',
  icon: 'blog',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BLOG_COMPACT_HORIZONTAL_WIDGET,
  preview: 'blogsMiniHorizontal'
}

export const SERMONS_WIDGET_COMPACT_HORIZONTAL = {
  name: 'Sermons (horizontal)',
  icon: 'sermons',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SERMONS_COMPACT_HORIZONTAL_WIDGET,
  preview: 'sermonsMiniHorizontal'
}

export const MINISTRIES_COMPACT_HORIZONTAL_WIDGET = {
  name: 'Ministries (horizontal)',
  icon: 'ministries',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_MINISTRIES_COMPACT_HORIZONTAL_WIDGET,
  preview: 'ministriesMiniHorizontal'
}

export const EVENTS_COMPACT_HORIZONTAL_WIDGET = {
  name: 'Events (horizontal)',
  icon: 'events',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET,
  preview: 'eventsMiniHorizontal'
}

export const QUOTE_WIDGET = {
  name: 'Quote',
  icon: 'Quote',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_QUOTE_WIDGET,
  preview: 'quote-mini-widget'
}

export const WATCH_US_ON_TV_TYPE2 = {
  name: 'Watch us on Tv Version 2',
  icon: 'watch',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WATCH_US_ON_TV_TYPE2_WIDGET,
  preview: 'watchNewDesign'
}

export const PODCASTS = {
  name: 'Listen on Podcasts',
  icon: 'podcasts',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PODCASTS,
  preview: 'listOnPodcast'
}

export const ADDRESS_COPY = {
  name: 'Copy Address',
  icon: 'podcasts',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ADDRESS_COPY,
  preview: 'addressCopy'
}

export const EVENTS_COMPACT_HORIZONTAL_WIDGET1 = {
  name: 'Events (horizontal)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET1,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_COMPACT_HORIZONTAL_WIDGET2 = {
  name: 'Events (horizontal) #Version 2',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET2,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET = {
  name: 'Events custom',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_BG = {
  name: 'Events custom (background)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_BG,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_BANNER = {
  name: 'Events custom (banner)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_BANNER,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_MINI = {
  name: 'Events custom (mini)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_MINI,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_NO_BG = {
  name: 'Events custom (card without bg)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_NO_BG,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_CALENDAR = {
  name: 'Events custom (calendar)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR,
  preview: 'eventsMiniHorizontal'
}

export const EVENTS_CUSTOM_WIDGET_CALENDAR_IMG = {
  name: 'Events custom (calendar with image)',
  icon: 'events',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR_IMG,
  preview: 'eventsMiniHorizontal'
}

export const READING_SECTION = {
  name: 'Reading (horizontal)',
  icon: 'readingPlanType2',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_READING_SECTION,
  preview: 'readingPlanType2'
}

export const DEVOTIONAL_SECTION = {
  name: 'Devotional Version2 (horizontal)',
  icon: 'devotionsType2',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_DEVOTIONAL_SECTION,
  preview: 'devotionsType2'
}

export const LATEST_UPDATE_HORIZONTAL_WIDGET = {
  name: 'Latest Update',
  icon: 'reading-plan',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_LATEST_UPDATE_HORIZONTAL_WIDGET,
  preview: 'readingPlanMiniHorizontal'
}

export const READING_PLAN_COMPACT_HORIZONTAL_WIDGET = {
  name: 'Reading Plan (horizontal)',
  icon: 'reading-plan',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_READING_PLAN_COMPACT_HORIZONTAL_WIDGET,
  preview: 'readingPlanMiniHorizontal'
}

export const TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET = {
  name: 'Testimonials (horizontal)',
  icon: 'testimonials',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET,
  preview: 'testimonialsMiniHorizontal'
}

export const CHART_WIDGET = {
  name: 'Chart',
  icon: 'chart',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_CHART_WIDGET,
  preview: 'chartMiniHorizontal'
}

export const MAP_TEMPLATE = {
  name: 'Map template',
  icon: 'map_template',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_MAP_TEMPLATE,
  preview: 'map'
}

export const PHOTOS_BLOCKS_ONE = {
  name: 'Photos block one',
  icon: 'content_blocks_one',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PHOTOS_BLOCKS_ONE,
  preview: 'quickFactsMiniHorizontal'
}

export const TYPOGRAPHY_BLOCKS_ONE = {
  // name: 'Photos block one',
  // icon: 'content_blocks_one',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TYPOGRAPHY_BLOCKS_ONE
  // preview: 'quickFactsMiniHorizontal'
}

export const PHOTO_BLOCKS_ONE = {
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PHOTO_BLOCKS_ONE,
  preview: 'quickFactsMiniHorizontal'
}

export const VIDEOS_BLOCKS_ONE = {
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_VIDEO_BLOCKS_ONE,
  preview: 'quickFactsMiniHorizontal'
}

export const ICONS_BLOCKS_ONE = {
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ICONS_BLOCKS_ONE,
  preview: 'quickFactsMiniHorizontal'
}

export const SHAPES_BLOCKS_ONE = {
  name: 'Circle',
  icon: 'Circle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SHAPES_BLOCKS_ONE
  // preview: 'quickFactsMiniHorizontal'
}

export const TEXTURES1_BLOCKS_ONE = {
  name: 'acrylic _1',
  icon: 'acrylic _1',
  type: TYPE_ELEMENT,
  view: 'texture',
  sub: subType.SUB_TYPE_TEXTURES1_BLOCKS_ONE
}

export const JOVIAL1_BLOCKS_ONE = {
  name: 'jovial_1',
  icon: 'jovial_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL1_BLOCKS_ONE
}

export const JOVIAL2_BLOCKS_ONE = {
  name: 'jovial_2',
  icon: 'jovial_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL2_BLOCKS_ONE
}

export const JOVIAL3_BLOCKS_ONE = {
  name: 'jovial_3',
  icon: 'jovial_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL3_BLOCKS_ONE
}

export const JOVIAL4_BLOCKS_ONE = {
  name: 'jovial_4',
  icon: 'jovial_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL4_BLOCKS_ONE
}

export const JOVIAL5_BLOCKS_ONE = {
  name: 'jovial_5',
  icon: 'jovial_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL5_BLOCKS_ONE
}

export const JOVIAL6_BLOCKS_ONE = {
  name: 'jovial_6',
  icon: 'jovial_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL6_BLOCKS_ONE
}

export const JOVIAL7_BLOCKS_ONE = {
  name: 'jovial_7',
  icon: 'jovial_7',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL7_BLOCKS_ONE
}

export const JOVIAL8_BLOCKS_ONE = {
  name: 'jovial_8',
  icon: 'jovial_8',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL8_BLOCKS_ONE
}

export const JOVIAL9_BLOCKS_ONE = {
  name: 'jovial_9',
  icon: 'jovial_9',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL9_BLOCKS_ONE
}

export const JOVIAL10_BLOCKS_ONE = {
  name: 'jovial_10',
  icon: 'jovial_10',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL10_BLOCKS_ONE
}

export const JOVIAL11_BLOCKS_ONE = {
  name: 'jovial_11',
  icon: 'jovial_11',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL11_BLOCKS_ONE
}

export const JOVIAL12_BLOCKS_ONE = {
  name: 'jovial_12',
  icon: 'jovial_12',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL12_BLOCKS_ONE
}

export const JOVIAL13_BLOCKS_ONE = {
  name: 'jovial_13',
  icon: 'jovial_13',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL13_BLOCKS_ONE
}

export const JOVIAL14_BLOCKS_ONE = {
  name: 'jovial_14',
  icon: 'jovial_14',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL14_BLOCKS_ONE
}

export const JOVIAL15_BLOCKS_ONE = {
  name: 'jovial_15',
  icon: 'jovial_15',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL15_BLOCKS_ONE
}

export const JOVIAL16_BLOCKS_ONE = {
  name: 'jovial_16',
  icon: 'jovial_16',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL16_BLOCKS_ONE
}

export const JOVIAL17_BLOCKS_ONE = {
  name: 'jovial_17',
  icon: 'jovial_17',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL17_BLOCKS_ONE
}

export const JOVIAL18_BLOCKS_ONE = {
  name: 'jovial_18',
  icon: 'jovial_18',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL18_BLOCKS_ONE
}

export const JOVIAL19_BLOCKS_ONE = {
  name: 'jovial_19',
  icon: 'jovial_19',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL19_BLOCKS_ONE
}

export const JOVIAL20_BLOCKS_ONE = {
  name: 'jovial_20',
  icon: 'jovial_20',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL20_BLOCKS_ONE
}

export const JOVIAL21_BLOCKS_ONE = {
  name: 'jovial_21',
  icon: 'jovial_21',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL21_BLOCKS_ONE
}

export const JOVIAL22_BLOCKS_ONE = {
  name: 'jovial_22',
  icon: 'jovial_22',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL22_BLOCKS_ONE
}

export const JOVIAL23_BLOCKS_ONE = {
  name: 'jovial_23',
  icon: 'jovial_23',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL23_BLOCKS_ONE
}

export const JOVIAL24_BLOCKS_ONE = {
  name: 'jovial_24',
  icon: 'jovial_24',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL24_BLOCKS_ONE
}

export const JOVIAL25_BLOCKS_ONE = {
  name: 'jovial_25',
  icon: 'jovial_25',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL25_BLOCKS_ONE
}

export const JOVIAL26_BLOCKS_ONE = {
  name: 'jovial_26',
  icon: 'jovial_26',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL26_BLOCKS_ONE
}

export const JOVIAL27_BLOCKS_ONE = {
  name: 'jovial_27',
  icon: 'jovial_27',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL27_BLOCKS_ONE
}

export const JOVIAL28_BLOCKS_ONE = {
  name: 'jovial_28',
  icon: 'jovial_28',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL28_BLOCKS_ONE
}

export const JOVIAL29_BLOCKS_ONE = {
  name: 'jovial_29',
  icon: 'jovial_29',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL29_BLOCKS_ONE
}

export const JOVIAL30_BLOCKS_ONE = {
  name: 'jovial_30',
  icon: 'jovial_30',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL30_BLOCKS_ONE
}

export const JOVIAL31_BLOCKS_ONE = {
  name: 'jovial_31',
  icon: 'jovial_31',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL31_BLOCKS_ONE
}

export const JOVIAL32_BLOCKS_ONE = {
  name: 'jovial_32',
  icon: 'jovial_32',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL32_BLOCKS_ONE
}

export const JOVIAL33_BLOCKS_ONE = {
  name: 'jovial_33',
  icon: 'jovial_33',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL33_BLOCKS_ONE
}

export const JOVIAL34_BLOCKS_ONE = {
  name: 'jovial_34',
  icon: 'jovial_34',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL34_BLOCKS_ONE
}

export const JOVIAL35_BLOCKS_ONE = {
  name: 'jovial_35',
  icon: 'jovial_35',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL35_BLOCKS_ONE
}

export const JOVIAL36_BLOCKS_ONE = {
  name: 'jovial_36',
  icon: 'jovial_36',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL36_BLOCKS_ONE
}

export const JOVIAL37_BLOCKS_ONE = {
  name: 'jovial_37',
  icon: 'jovial_37',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL37_BLOCKS_ONE
}

export const JOVIAL38_BLOCKS_ONE = {
  name: 'jovial_38',
  icon: 'jovial_38',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL38_BLOCKS_ONE
}

export const JOVIAL39_BLOCKS_ONE = {
  name: 'jovial_39',
  icon: 'jovial_39',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL39_BLOCKS_ONE
}

export const JOVIAL40_BLOCKS_ONE = {
  name: 'jovial_40',
  icon: 'jovial_40',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL40_BLOCKS_ONE
}

export const JOVIAL41_BLOCKS_ONE = {
  name: 'jovial_41',
  icon: 'jovial_41',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL41_BLOCKS_ONE
}

export const JOVIAL42_BLOCKS_ONE = {
  name: 'jovial_42',
  icon: 'jovial_42',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL42_BLOCKS_ONE
}

export const JOVIAL43_BLOCKS_ONE = {
  name: 'jovial_43',
  icon: 'jovial_43',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL43_BLOCKS_ONE
}

export const JOVIAL44_BLOCKS_ONE = {
  name: 'jovial_44',
  icon: 'jovial_44',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL44_BLOCKS_ONE
}

export const JOVIAL45_BLOCKS_ONE = {
  name: 'jovial_45',
  icon: 'jovial_45',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL45_BLOCKS_ONE
}

export const JOVIAL46_BLOCKS_ONE = {
  name: 'jovial_46',
  icon: 'jovial_46',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL46_BLOCKS_ONE
}

export const JOVIAL47_BLOCKS_ONE = {
  name: 'jovial_47',
  icon: 'jovial_47',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL47_BLOCKS_ONE
}

export const JOVIAL48_BLOCKS_ONE = {
  name: 'jovial_48',
  icon: 'jovial_48',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL48_BLOCKS_ONE
}

export const JOVIAL49_BLOCKS_ONE = {
  name: 'jovial_49',
  icon: 'jovial_49',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL49_BLOCKS_ONE
}

export const JOVIAL50_BLOCKS_ONE = {
  name: 'jovial_50',
  icon: 'jovial_50',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL50_BLOCKS_ONE
}

export const JOVIAL51_BLOCKS_ONE = {
  name: 'jovial_51',
  icon: 'jovial_51',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL51_BLOCKS_ONE
}

export const JOVIAL52_BLOCKS_ONE = {
  name: 'jovial_52',
  icon: 'jovial_52',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL52_BLOCKS_ONE
}

export const JOVIAL53_BLOCKS_ONE = {
  name: 'jovial_53',
  icon: 'jovial_53',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL53_BLOCKS_ONE
}

export const JOVIAL54_BLOCKS_ONE = {
  name: 'jovial_54',
  icon: 'jovial_54',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL54_BLOCKS_ONE
}

export const JOVIAL55_BLOCKS_ONE = {
  name: 'jovial_55',
  icon: 'jovial_55',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL55_BLOCKS_ONE
}

export const JOVIAL56_BLOCKS_ONE = {
  name: 'jovial_56',
  icon: 'jovial_56',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL56_BLOCKS_ONE
}

export const JOVIAL57_BLOCKS_ONE = {
  name: 'jovial_57',
  icon: 'jovial_57',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL57_BLOCKS_ONE
}

export const JOVIAL58_BLOCKS_ONE = {
  name: 'jovial_58',
  icon: 'jovial_58',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL58_BLOCKS_ONE
}

export const JOVIAL59_BLOCKS_ONE = {
  name: 'jovial_59',
  icon: 'jovial_59',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL59_BLOCKS_ONE
}

export const JOVIAL60_BLOCKS_ONE = {
  name: 'jovial_60',
  icon: 'jovial_60',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL60_BLOCKS_ONE
}

export const JOVIAL61_BLOCKS_ONE = {
  name: 'jovial_61',
  icon: 'jovial_61',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL61_BLOCKS_ONE
}

export const JOVIAL62_BLOCKS_ONE = {
  name: 'jovial_62',
  icon: 'jovial_62',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL62_BLOCKS_ONE
}

export const JOVIAL63_BLOCKS_ONE = {
  name: 'jovial_63',
  icon: 'jovial_63',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL63_BLOCKS_ONE
}

export const JOVIAL64_BLOCKS_ONE = {
  name: 'jovial_64',
  icon: 'jovial_64',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL64_BLOCKS_ONE
}

export const JOVIAL65_BLOCKS_ONE = {
  name: 'jovial_65',
  icon: 'jovial_65',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL65_BLOCKS_ONE
}

export const JOVIAL66_BLOCKS_ONE = {
  name: 'jovial_66',
  icon: 'jovial_66',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL66_BLOCKS_ONE
}

export const JOVIAL67_BLOCKS_ONE = {
  name: 'jovial_67',
  icon: 'jovial_67',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL67_BLOCKS_ONE
}

export const JOVIAL68_BLOCKS_ONE = {
  name: 'jovial_68',
  icon: 'jovial_68',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL68_BLOCKS_ONE
}

export const JOVIAL69_BLOCKS_ONE = {
  name: 'jovial_69',
  icon: 'jovial_69',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL69_BLOCKS_ONE
}

export const JOVIAL70_BLOCKS_ONE = {
  name: 'jovial_70',
  icon: 'jovial_70',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL70_BLOCKS_ONE
}

export const JOVIAL71_BLOCKS_ONE = {
  name: 'jovial_71',
  icon: 'jovial_71',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL71_BLOCKS_ONE
}

export const JOVIAL72_BLOCKS_ONE = {
  name: 'jovial_72',
  icon: 'jovial_72',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL72_BLOCKS_ONE
}

export const JOVIAL73_BLOCKS_ONE = {
  name: 'jovial_73',
  icon: 'jovial_73',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL73_BLOCKS_ONE
}

export const JOVIAL74_BLOCKS_ONE = {
  name: 'jovial_74',
  icon: 'jovial_74',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL74_BLOCKS_ONE
}

export const JOVIAL75_BLOCKS_ONE = {
  name: 'jovial_75',
  icon: 'jovial_75',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_JOVIAL75_BLOCKS_ONE
}

export const ORNAMENT1_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_1',
  icon: 'ornaments_tall_abbey_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT1_BLOCKS_ONE
}

export const ORNAMENT2_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_2',
  icon: 'ornaments_tall_abbey_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT2_BLOCKS_ONE
}

export const ORNAMENT3_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_3',
  icon: 'ornaments_tall_abbey_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT3_BLOCKS_ONE
}

export const ORNAMENT4_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_4',
  icon: 'ornaments_tall_abbey_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT4_BLOCKS_ONE
}

export const ORNAMENT5_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_5',
  icon: 'ornaments_tall_abbey_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT5_BLOCKS_ONE
}

export const ORNAMENT6_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_6',
  icon: 'ornaments_tall_abbey_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT6_BLOCKS_ONE
}

export const ORNAMENT7_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_7',
  icon: 'ornaments_tall_abbey_7',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT7_BLOCKS_ONE
}

export const ORNAMENT8_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_8',
  icon: 'ornaments_tall_abbey_8',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT8_BLOCKS_ONE
}

export const ORNAMENT9_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_9',
  icon: 'ornaments_tall_abbey_9',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT9_BLOCKS_ONE
}

export const ORNAMENT10_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_10',
  icon: 'ornaments_tall_abbey_10',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT10_BLOCKS_ONE
}

export const ORNAMENT11_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_11',
  icon: 'ornaments_tall_abbey_11',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT11_BLOCKS_ONE
}

export const ORNAMENT12_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_12',
  icon: 'ornaments_tall_abbey_12',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT12_BLOCKS_ONE
}

export const ORNAMENT13_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_13',
  icon: 'ornaments_tall_abbey_13',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT13_BLOCKS_ONE
}

export const ORNAMENT14_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_14',
  icon: 'ornaments_tall_abbey_14',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT14_BLOCKS_ONE
}
export const ORNAMENT15_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_15',
  icon: 'ornaments_tall_abbey_15',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT15_BLOCKS_ONE
}

export const ORNAMENT16_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_16',
  icon: 'ornaments_tall_abbey_16',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT16_BLOCKS_ONE
}

export const ORNAMENT17_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_17',
  icon: 'ornaments_tall_abbey_17',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT17_BLOCKS_ONE
}

export const ORNAMENT18_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_18',
  icon: 'ornaments_tall_abbey_18',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT18_BLOCKS_ONE
}

export const ORNAMENT19_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_19',
  icon: 'ornaments_tall_abbey_19',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT19_BLOCKS_ONE
}

export const ORNAMENT20_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_20',
  icon: 'ornaments_tall_abbey_20',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT20_BLOCKS_ONE
}

export const ORNAMENT21_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_21',
  icon: 'ornaments_tall_abbey_21',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT21_BLOCKS_ONE
}

export const ORNAMENT22_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_22',
  icon: 'ornaments_tall_abbey_22',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT22_BLOCKS_ONE
}

export const ORNAMENT23_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_23',
  icon: 'ornaments_tall_abbey_23',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT23_BLOCKS_ONE
}

export const ORNAMENT24_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_24',
  icon: 'ornaments_tall_abbey_24',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT24_BLOCKS_ONE
}

export const ORNAMENT25_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_25',
  icon: 'ornaments_tall_abbey_25',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT25_BLOCKS_ONE
}

export const ORNAMENT26_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_26',
  icon: 'ornaments_tall_abbey_26',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT26_BLOCKS_ONE
}

export const ORNAMENT27_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_27',
  icon: 'ornaments_tall_abbey_27',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT27_BLOCKS_ONE
}

export const ORNAMENT28_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_28',
  icon: 'ornaments_tall_abbey_28',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT28_BLOCKS_ONE
}

export const ORNAMENT29_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_29',
  icon: 'ornaments_tall_abbey_29',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT29_BLOCKS_ONE
}

export const ORNAMENT30_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_30',
  icon: 'ornaments_tall_abbey_30',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT30_BLOCKS_ONE
}

export const ORNAMENT31_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_31',
  icon: 'ornaments_tall_abbey_31',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT31_BLOCKS_ONE
}

export const ORNAMENT32_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_32',
  icon: 'ornaments_tall_abbey_32',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT32_BLOCKS_ONE
}

export const ORNAMENT33_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_33',
  icon: 'ornaments_tall_abbey_33',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT33_BLOCKS_ONE
}

export const ORNAMENT34_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_34',
  icon: 'ornaments_tall_abbey_34',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT34_BLOCKS_ONE
}

export const ORNAMENT35_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_35',
  icon: 'ornaments_tall_abbey_35',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT35_BLOCKS_ONE
}

export const ORNAMENT36_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_36',
  icon: 'ornaments_tall_abbey_36',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT36_BLOCKS_ONE
}

export const ORNAMENT37_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_37',
  icon: 'ornaments_tall_abbey_37',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT37_BLOCKS_ONE
}

export const ORNAMENT38_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_38',
  icon: 'ornaments_tall_abbey_38',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT38_BLOCKS_ONE
}

export const ORNAMENT39_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_39',
  icon: 'ornaments_tall_abbey_39',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT39_BLOCKS_ONE
}

export const ORNAMENT40_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_40',
  icon: 'ornaments_tall_abbey_40',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT40_BLOCKS_ONE
}

export const ORNAMENT41_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_41',
  icon: 'ornaments_tall_abbey_41',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT41_BLOCKS_ONE
}

export const ORNAMENT42_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_42',
  icon: 'ornaments_tall_abbey_42',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT42_BLOCKS_ONE
}

export const ORNAMENT43_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_43',
  icon: 'ornaments_tall_abbey_43',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT43_BLOCKS_ONE
}

export const ORNAMENT44_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_44',
  icon: 'ornaments_tall_abbey_44',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT44_BLOCKS_ONE
}

export const ORNAMENT45_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_45',
  icon: 'ornaments_tall_abbey_45',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT45_BLOCKS_ONE
}

export const ORNAMENT46_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_46',
  icon: 'ornaments_tall_abbey_46',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT46_BLOCKS_ONE
}

export const ORNAMENT47_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_47',
  icon: 'ornaments_tall_abbey_47',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT47_BLOCKS_ONE
}

export const ORNAMENT48_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_48',
  icon: 'ornaments_tall_abbey_48',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT48_BLOCKS_ONE
}

export const ORNAMENT49_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_49',
  icon: 'ornaments_tall_abbey_49',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT49_BLOCKS_ONE
}

export const ORNAMENT50_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_50',
  icon: 'ornaments_tall_abbey_50',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT50_BLOCKS_ONE
}

export const ORNAMENT51_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_51',
  icon: 'ornaments_tall_abbey_51',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT51_BLOCKS_ONE
}

export const ORNAMENT52_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_52',
  icon: 'ornaments_tall_abbey_52',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT52_BLOCKS_ONE
}

export const ORNAMENT53_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_53',
  icon: 'ornaments_tall_abbey_53',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT53_BLOCKS_ONE
}

export const ORNAMENT54_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_54',
  icon: 'ornaments_tall_abbey_54',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT54_BLOCKS_ONE
}

export const ORNAMENT55_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_55',
  icon: 'ornaments_tall_abbey_55',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT55_BLOCKS_ONE
}

export const ORNAMENT56_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_56',
  icon: 'ornaments_tall_abbey_56',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT56_BLOCKS_ONE
}

export const ORNAMENT57_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_57',
  icon: 'ornaments_tall_abbey_57',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT57_BLOCKS_ONE
}

export const ORNAMENT58_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_58',
  icon: 'ornaments_tall_abbey_58',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT58_BLOCKS_ONE
}

export const ORNAMENT59_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_59',
  icon: 'ornaments_tall_abbey_59',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT59_BLOCKS_ONE
}

export const ORNAMENT60_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_60',
  icon: 'ornaments_tall_abbey_60',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT60_BLOCKS_ONE
}

export const ORNAMENT61_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_61',
  icon: 'ornaments_tall_abbey_61',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT61_BLOCKS_ONE
}

export const ORNAMENT62_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_62',
  icon: 'ornaments_tall_abbey_62',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT62_BLOCKS_ONE
}

export const ORNAMENT63_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_63',
  icon: 'ornaments_tall_abbey_63',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT63_BLOCKS_ONE
}

export const ORNAMENT64_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_64',
  icon: 'ornaments_tall_abbey_64',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT64_BLOCKS_ONE
}

export const ORNAMENT65_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_65',
  icon: 'ornaments_tall_abbey_65',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT65_BLOCKS_ONE
}

export const ORNAMENT66_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_66',
  icon: 'ornaments_tall_abbey_66',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT66_BLOCKS_ONE
}

export const ORNAMENT67_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_67',
  icon: 'ornaments_tall_abbey_67',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT67_BLOCKS_ONE
}

export const ORNAMENT68_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_68',
  icon: 'ornaments_tall_abbey_68',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT68_BLOCKS_ONE
}

export const ORNAMENT69_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_69',
  icon: 'ornaments_tall_abbey_69',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT69_BLOCKS_ONE
}

export const ORNAMENT70_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_70',
  icon: 'ornaments_tall_abbey_70',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT70_BLOCKS_ONE
}

export const ORNAMENT71_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_71',
  icon: 'ornaments_tall_abbey_71',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT71_BLOCKS_ONE
}

export const ORNAMENT72_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_72',
  icon: 'ornaments_tall_abbey_71',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT72_BLOCKS_ONE
}

export const ORNAMENT73_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_73',
  icon: 'ornaments_tall_abbey_73',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT73_BLOCKS_ONE
}

export const ORNAMENT74_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_74',
  icon: 'ornaments_tall_abbey_74',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT74_BLOCKS_ONE
}

export const ORNAMENT75_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_75',
  icon: 'ornaments_tall_abbey_75',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT75_BLOCKS_ONE
}

export const ORNAMENT76_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_76',
  icon: 'ornaments_tall_abbey_76',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT76_BLOCKS_ONE
}

export const ORNAMENT77_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_77',
  icon: 'ornaments_tall_abbey_77',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT77_BLOCKS_ONE
}

export const ORNAMENT78_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_78',
  icon: 'ornaments_tall_abbey_78',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT78_BLOCKS_ONE
}

export const ORNAMENT79_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_79',
  icon: 'ornaments_tall_abbey_79',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT79_BLOCKS_ONE
}

export const ORNAMENT80_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_80',
  icon: 'ornaments_tall_abbey_80',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT80_BLOCKS_ONE
}

export const ORNAMENT81_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_81',
  icon: 'ornaments_tall_abbey_81',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT81_BLOCKS_ONE
}

export const ORNAMENT82_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_82',
  icon: 'ornaments_tall_abbey_82',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT82_BLOCKS_ONE
}

export const ORNAMENT83_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_83',
  icon: 'ornaments_tall_abbey_83',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT83_BLOCKS_ONE
}

export const ORNAMENT84_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_84',
  icon: 'ornaments_tall_abbey_84',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT84_BLOCKS_ONE
}

export const ORNAMENT85_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_85',
  icon: 'ornaments_tall_abbey_85',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT85_BLOCKS_ONE
}

export const ORNAMENT86_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_86',
  icon: 'ornaments_tall_abbey_86',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT86_BLOCKS_ONE
}

export const ORNAMENT87_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_87',
  icon: 'ornaments_tall_abbey_87',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT87_BLOCKS_ONE
}

export const ORNAMENT88_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_88',
  icon: 'ornaments_tall_abbey_88',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT88_BLOCKS_ONE
}

export const ORNAMENT89_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_89',
  icon: 'ornaments_tall_abbey_89',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT89_BLOCKS_ONE
}

export const ORNAMENT90_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_90',
  icon: 'ornaments_tall_abbey_90',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT90_BLOCKS_ONE
}

export const ORNAMENT91_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_91',
  icon: 'ornaments_tall_abbey_91',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT91_BLOCKS_ONE
}

export const ORNAMENT92_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_92',
  icon: 'ornaments_tall_abbey_92',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT92_BLOCKS_ONE
}

export const ORNAMENT93_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_93',
  icon: 'ornaments_tall_abbey_93',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT93_BLOCKS_ONE
}

export const ORNAMENT94_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_94',
  icon: 'ornaments_tall_abbey_94',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT94_BLOCKS_ONE
}

export const ORNAMENT95_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_95',
  icon: 'ornaments_tall_abbey_95',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT95_BLOCKS_ONE
}

export const ORNAMENT96_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_96',
  icon: 'ornaments_tall_abbey_96',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT96_BLOCKS_ONE
}

export const ORNAMENT97_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_97',
  icon: 'ornaments_tall_abbey_97',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT97_BLOCKS_ONE
}

export const ORNAMENT98_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_98',
  icon: 'ornaments_tall_abbey_98',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT98_BLOCKS_ONE
}

export const ORNAMENT99_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_99',
  icon: 'ornaments_tall_abbey_99',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT99_BLOCKS_ONE
}

export const ORNAMENT100_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_100',
  icon: 'ornaments_tall_abbey_100',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT100_BLOCKS_ONE
}

export const ORNAMENT101_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_101',
  icon: 'ornaments_tall_abbey_101',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT101_BLOCKS_ONE
}

export const ORNAMENT102_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_102',
  icon: 'ornaments_tall_abbey_102',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT102_BLOCKS_ONE
}

export const ORNAMENT103_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_103',
  icon: 'ornaments_tall_abbey_103',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT103_BLOCKS_ONE
}

export const ORNAMENT104_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_104',
  icon: 'ornaments_tall_abbey_104',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT104_BLOCKS_ONE
}

export const ORNAMENT105_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_105',
  icon: 'ornaments_tall_abbey_105',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT105_BLOCKS_ONE
}

export const ORNAMENT106_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_106',
  icon: 'ornaments_tall_abbey_106',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT106_BLOCKS_ONE
}

export const ORNAMENT107_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_107',
  icon: 'ornaments_tall_abbey_107',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT107_BLOCKS_ONE
}

export const ORNAMENT108_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_108',
  icon: 'ornaments_tall_abbey_108',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT108_BLOCKS_ONE
}

export const ORNAMENT109_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_109',
  icon: 'ornaments_tall_abbey_109',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT109_BLOCKS_ONE
}

export const ORNAMENT110_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_110',
  icon: 'ornaments_tall_abbey_110',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT110_BLOCKS_ONE
}

export const ORNAMENT111_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_111',
  icon: 'ornaments_tall_abbey_111',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT111_BLOCKS_ONE
}

export const ORNAMENT112_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_112',
  icon: 'ornaments_tall_abbey_112',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT112_BLOCKS_ONE
}

export const ORNAMENT113_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_113',
  icon: 'ornaments_tall_abbey_113',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT113_BLOCKS_ONE
}

export const ORNAMENT114_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_114',
  icon: 'ornaments_tall_abbey_114',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT114_BLOCKS_ONE
}

export const ORNAMENT115_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_115',
  icon: 'ornaments_tall_abbey_115',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT115_BLOCKS_ONE
}

export const ORNAMENT116_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_116',
  icon: 'ornaments_tall_abbey_116',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT116_BLOCKS_ONE
}

export const ORNAMENT117_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_117',
  icon: 'ornaments_tall_abbey_117',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT117_BLOCKS_ONE
}

export const ORNAMENT118_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_118',
  icon: 'ornaments_tall_abbey_118',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT118_BLOCKS_ONE
}

export const ORNAMENT119_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_119',
  icon: 'ornaments_tall_abbey_119',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT119_BLOCKS_ONE
}

export const ORNAMENT120_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_120',
  icon: 'ornaments_tall_abbey_120',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT120_BLOCKS_ONE
}

export const ORNAMENT121_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_121',
  icon: 'ornaments_tall_abbey_121',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT121_BLOCKS_ONE
}

export const ORNAMENT122_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_122',
  icon: 'ornaments_tall_abbey_122',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT122_BLOCKS_ONE
}

export const ORNAMENT123_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_123',
  icon: 'ornaments_tall_abbey_123',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT123_BLOCKS_ONE
}

export const ORNAMENT124_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_124',
  icon: 'ornaments_tall_abbey_124',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT124_BLOCKS_ONE
}

export const ORNAMENT125_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_125',
  icon: 'ornaments_tall_abbey_125',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT125_BLOCKS_ONE
}

export const ORNAMENT126_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_126',
  icon: 'ornaments_tall_abbey_126',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT126_BLOCKS_ONE
}

export const ORNAMENT127_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_127',
  icon: 'ornaments_tall_abbey_127',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT127_BLOCKS_ONE
}

export const ORNAMENT128_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_128',
  icon: 'ornaments_tall_abbey_128',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT128_BLOCKS_ONE
}

export const ORNAMENT129_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_129',
  icon: 'ornaments_tall_abbey_129',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT129_BLOCKS_ONE
}

export const ORNAMENT130_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_130',
  icon: 'ornaments_tall_abbey_130',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT130_BLOCKS_ONE
}

export const ORNAMENT131_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_131',
  icon: 'ornaments_tall_abbey_131',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT131_BLOCKS_ONE
}

export const ORNAMENT132_BLOCKS_ONE = {
  name: 'ornaments_tall_abbey_132',
  icon: 'ornaments_tall_abbey_132',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_ORNAMENT132_BLOCKS_ONE
}

export const SWASH1_BLOCKS_ONE = {
  name: 'swash_1',
  icon: 'swash_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH1_BLOCKS_ONE
}

export const BRIXTON_SANS1_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_1',
  icon: 'BrixtonSansExtras_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS1_BLOCKS_ONE
}

export const BRIXTON_SANS2_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_2',
  icon: 'BrixtonSansExtras_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS2_BLOCKS_ONE
}

export const BRIXTON_SANS3_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_3',
  icon: 'BrixtonSansExtras_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS3_BLOCKS_ONE
}

export const BRIXTON_SANS4_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_4',
  icon: 'BrixtonSansExtras_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS4_BLOCKS_ONE
}

export const BRIXTON_SANS5_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_5',
  icon: 'BrixtonSansExtras_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS5_BLOCKS_ONE
}

export const BRIXTON_SANS6_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_6',
  icon: 'BrixtonSansExtras_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS6_BLOCKS_ONE
}

export const BRIXTON_SANS7_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_7',
  icon: 'BrixtonSansExtras_7',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS7_BLOCKS_ONE
}

export const BRIXTON_SANS8_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_8',
  icon: 'BrixtonSansExtras_8',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS8_BLOCKS_ONE
}

export const BRIXTON_SANS9_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_9',
  icon: 'BrixtonSansExtras_9',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS9_BLOCKS_ONE
}

export const BRIXTON_SANS10_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_10',
  icon: 'BrixtonSansExtras_10',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS10_BLOCKS_ONE
}

export const BRIXTON_SANS11_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_11',
  icon: 'BrixtonSansExtras_11',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS11_BLOCKS_ONE
}

export const BRIXTON_SANS12_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_12',
  icon: 'BrixtonSansExtras_12',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS12_BLOCKS_ONE
}

export const BRIXTON_SANS13_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_13',
  icon: 'BrixtonSansExtras_13',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS13_BLOCKS_ONE
}

export const BRIXTON_SANS14_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_14',
  icon: 'BrixtonSansExtras_14',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS14_BLOCKS_ONE
}

export const BRIXTON_SANS15_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_15',
  icon: 'BrixtonSansExtras_15',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS15_BLOCKS_ONE
}

export const BRIXTON_SANS16_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_16',
  icon: 'BrixtonSansExtras_16',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS16_BLOCKS_ONE
}

export const BRIXTON_SANS17_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_17',
  icon: 'BrixtonSansExtras_17',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS17_BLOCKS_ONE
}

export const BRIXTON_SANS18_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_18',
  icon: 'BrixtonSansExtras_18',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS18_BLOCKS_ONE
}

export const BRIXTON_SANS19_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_19',
  icon: 'BrixtonSansExtras_19',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS19_BLOCKS_ONE
}

export const BRIXTON_SANS20_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_20',
  icon: 'BrixtonSansExtras_20',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS20_BLOCKS_ONE
}

export const BRIXTON_SANS21_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_21',
  icon: 'BrixtonSansExtras_21',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS21_BLOCKS_ONE
}

export const BRIXTON_SANS22_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_22',
  icon: 'BrixtonSansExtras_22',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS22_BLOCKS_ONE
}

export const BRIXTON_SANS23_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_23',
  icon: 'BrixtonSansExtras_23',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS23_BLOCKS_ONE
}

export const BRIXTON_SANS24_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_24',
  icon: 'BrixtonSansExtras_24',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS24_BLOCKS_ONE
}

export const BRIXTON_SANS25_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_25',
  icon: 'BrixtonSansExtras_25',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS25_BLOCKS_ONE
}

export const BRIXTON_SANS26_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_26',
  icon: 'BrixtonSansExtras_26',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS26_BLOCKS_ONE
}

export const BRIXTON_SANS27_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_27',
  icon: 'BrixtonSansExtras_27',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS27_BLOCKS_ONE
}

export const BRIXTON_SANS28_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_28',
  icon: 'BrixtonSansExtras_28',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS28_BLOCKS_ONE
}

export const BRIXTON_SANS29_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_29',
  icon: 'BrixtonSansExtras_29',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS29_BLOCKS_ONE
}

export const BRIXTON_SANS30_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_30',
  icon: 'BrixtonSansExtras_30',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS30_BLOCKS_ONE
}

export const BRIXTON_SANS31_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_31',
  icon: 'BrixtonSansExtras_31',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS31_BLOCKS_ONE
}

export const BRIXTON_SANS32_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_32',
  icon: 'BrixtonSansExtras_32',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS32_BLOCKS_ONE
}

export const BRIXTON_SANS33_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_33',
  icon: 'BrixtonSansExtras_33',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS33_BLOCKS_ONE
}

export const BRIXTON_SANS34_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_34',
  icon: 'BrixtonSansExtras_34',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS34_BLOCKS_ONE
}

export const BRIXTON_SANS35_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_35',
  icon: 'BrixtonSansExtras_35',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS35_BLOCKS_ONE
}

export const BRIXTON_SANS36_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_36',
  icon: 'BrixtonSansExtras_36',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS36_BLOCKS_ONE
}

export const BRIXTON_SANS37_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_37',
  icon: 'BrixtonSansExtras_37',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS37_BLOCKS_ONE
}

export const BRIXTON_SANS38_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_38',
  icon: 'BrixtonSansExtras_38',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS38_BLOCKS_ONE
}

export const BRIXTON_SANS39_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_39',
  icon: 'BrixtonSansExtras_39',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS39_BLOCKS_ONE
}

export const BRIXTON_SANS40_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_40',
  icon: 'BrixtonSansExtras_40',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS40_BLOCKS_ONE
}

export const BRIXTON_SANS41_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_41',
  icon: 'BrixtonSansExtras_41',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS41_BLOCKS_ONE
}

export const BRIXTON_SANS42_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_42',
  icon: 'BrixtonSansExtras_42',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS42_BLOCKS_ONE
}

export const BRIXTON_SANS43_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_43',
  icon: 'BrixtonSansExtras_43',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS43_BLOCKS_ONE
}

export const BRIXTON_SANS44_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_44',
  icon: 'BrixtonSansExtras_44',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS44_BLOCKS_ONE
}

export const BRIXTON_SANS45_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_45',
  icon: 'BrixtonSansExtras_45',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS45_BLOCKS_ONE
}

export const BRIXTON_SANS46_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_46',
  icon: 'BrixtonSansExtras_46',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS46_BLOCKS_ONE
}

export const BRIXTON_SANS47_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_47',
  icon: 'BrixtonSansExtras_47',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS47_BLOCKS_ONE
}

export const BRIXTON_SANS48_BLOCKS_ONE = {
  name: 'BrixtonSansExtras_48',
  icon: 'BrixtonSansExtras_48',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIXTON_SANS48_BLOCKS_ONE
}

export const SWASH2_BLOCKS_ONE = {
  name: 'swash_2',
  icon: 'swash_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH2_BLOCKS_ONE
}

export const SWASH3_BLOCKS_ONE = {
  name: 'swash_3',
  icon: 'swash_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH3_BLOCKS_ONE
}

export const SWASH4_BLOCKS_ONE = {
  name: 'swash_4',
  icon: 'swash_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH4_BLOCKS_ONE
}

export const SWASH5_BLOCKS_ONE = {
  name: 'swash_5',
  icon: 'swash_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH5_BLOCKS_ONE
}

export const SWASH6_BLOCKS_ONE = {
  name: 'swash_6',
  icon: 'swash_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH6_BLOCKS_ONE
}

export const SWASH7_BLOCKS_ONE = {
  name: 'swash_7',
  icon: 'swash_7',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH7_BLOCKS_ONE
}

export const SWASH8_BLOCKS_ONE = {
  name: 'swash_8',
  icon: 'swash_8',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH8_BLOCKS_ONE
}

export const SWASH9_BLOCKS_ONE = {
  name: 'swash_9',
  icon: 'swash_9',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH9_BLOCKS_ONE
}

export const SWASH10_BLOCKS_ONE = {
  name: 'swash_10',
  icon: 'swash_10',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_SWASH10_BLOCKS_ONE
}

export const LOGO1_BLOCKS_ONE = {
  name: 'logo_1',
  icon: 'serif_logo_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO1_BLOCKS_ONE
}

export const LOGO2_BLOCKS_ONE = {
  name: 'logo_2',
  icon: 'serif_logo_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO2_BLOCKS_ONE
}

export const LOGO3_BLOCKS_ONE = {
  name: 'logo_3',
  icon: 'serif_logo_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO3_BLOCKS_ONE
}

export const LOGO4_BLOCKS_ONE = {
  name: 'logo_4',
  icon: 'serif_logo_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO4_BLOCKS_ONE
}

export const LOGO5_BLOCKS_ONE = {
  name: 'logo_5',
  icon: 'serif_logo_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO5_BLOCKS_ONE
}

export const LOGO6_BLOCKS_ONE = {
  name: 'logo_6',
  icon: 'serif_logo_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_LOGO6_BLOCKS_ONE
}

export const BRIHTON1_BLOCKS_ONE = {
  name: 'brixton_1',
  icon: 'brixton_1',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON1_BLOCKS_ONE
}

export const BRIHTON2_BLOCKS_ONE = {
  name: 'brixton_2',
  icon: 'brixton_2',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON2_BLOCKS_ONE
}

export const BRIHTON3_BLOCKS_ONE = {
  name: 'brixton_3',
  icon: 'brixton_3',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON3_BLOCKS_ONE
}

export const BRIHTON4_BLOCKS_ONE = {
  name: 'brixton_4',
  icon: 'brixton_4',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON4_BLOCKS_ONE
}

export const BRIHTON5_BLOCKS_ONE = {
  name: 'brixton_5',
  icon: 'brixton_5',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON5_BLOCKS_ONE
}

export const BRIHTON6_BLOCKS_ONE = {
  name: 'brixton_6',
  icon: 'brixton_6',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON6_BLOCKS_ONE
}

export const BRIHTON7_BLOCKS_ONE = {
  name: 'brixton_7',
  icon: 'brixton_7',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON7_BLOCKS_ONE
}

export const BRIHTON8_BLOCKS_ONE = {
  name: 'brixton_8',
  icon: 'brixton_8',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON8_BLOCKS_ONE
}

export const BRIHTON9_BLOCKS_ONE = {
  name: 'brixton_9',
  icon: 'brixton_9',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON9_BLOCKS_ONE
}

export const BRIHTON10_BLOCKS_ONE = {
  name: 'brixton_10',
  icon: 'brixton_10',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON10_BLOCKS_ONE
}

export const BRIHTON11_BLOCKS_ONE = {
  name: 'brixton_11',
  icon: 'brixton_11',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON11_BLOCKS_ONE
}

export const BRIHTON12_BLOCKS_ONE = {
  name: 'brixton_12',
  icon: 'brixton_12',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON12_BLOCKS_ONE
}

export const BRIHTON13_BLOCKS_ONE = {
  name: 'brixton_13',
  icon: 'brixton_13',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON13_BLOCKS_ONE
}

export const BRIHTON14_BLOCKS_ONE = {
  name: 'brixton_14',
  icon: 'brixton_14',
  type: TYPE_ELEMENT,
  view: 'ornament',
  sub: subType.SUB_TYPE_BRIHTON14_BLOCKS_ONE
}

export const WEB_ARROWS1_BLOCKS_ONE = {
  name: 'Web Arrows 1',
  icon: 'web_arrows_1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS1_BLOCKS_ONE
}

export const WEB_ARROWS2_BLOCKS_ONE = {
  name: 'Web Arrows 2',
  icon: 'web_arrows_2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS2_BLOCKS_ONE
}

export const WEB_ARROWS3_BLOCKS_ONE = {
  name: 'Web Arrows 3',
  icon: 'web_arrows_3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS3_BLOCKS_ONE
}

export const WEB_ARROWS4_BLOCKS_ONE = {
  name: 'Web Arrows 4',
  icon: 'web_arrows_4',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS4_BLOCKS_ONE
}

export const WEB_ARROWS5_BLOCKS_ONE = {
  name: 'Web Arrows 5',
  icon: 'web_arrows_5',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS5_BLOCKS_ONE
}

export const WEB_ARROWS6_BLOCKS_ONE = {
  name: 'Web Arrows 6',
  icon: 'web_arrows_6',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS6_BLOCKS_ONE
}

export const WEB_ARROWS7_BLOCKS_ONE = {
  name: 'Web Arrows 7',
  icon: 'web_arrows_7',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS7_BLOCKS_ONE
}

export const WEB_ARROWS8_BLOCKS_ONE = {
  name: 'Web Arrows 8',
  icon: 'web_arrows_8',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS8_BLOCKS_ONE
}

export const WEB_ARROWS9_BLOCKS_ONE = {
  name: 'Web Arrows 9',
  icon: 'web_arrows_9',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS9_BLOCKS_ONE
}

export const WEB_ARROWS10_BLOCKS_ONE = {
  name: 'Web Arrows 10',
  icon: 'web_arrows_10',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS10_BLOCKS_ONE
}

export const WEB_ARROWS11_BLOCKS_ONE = {
  name: 'Web Arrows 11',
  icon: 'web_arrows_11',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS11_BLOCKS_ONE
}

export const WEB_ARROWS12_BLOCKS_ONE = {
  name: 'Web Arrows 12',
  icon: 'web_arrows_12',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS12_BLOCKS_ONE
}

export const WEB_ARROWS13_BLOCKS_ONE = {
  name: 'Web Arrows 13',
  icon: 'web_arrows_13',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS13_BLOCKS_ONE
}

export const WEB_ARROWS14_BLOCKS_ONE = {
  name: 'Web Arrows 14',
  icon: 'web_arrows_14',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS14_BLOCKS_ONE
}

export const WEB_ARROWS15_BLOCKS_ONE = {
  name: 'Web Arrows 15',
  icon: 'web_arrows_15',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS15_BLOCKS_ONE
}

export const WEB_ARROWS16_BLOCKS_ONE = {
  name: 'Web Arrows 16',
  icon: 'web_arrows_16',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS16_BLOCKS_ONE
}

export const WEB_ARROWS17_BLOCKS_ONE = {
  name: 'Web Arrows 17',
  icon: 'web_arrows_17',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS17_BLOCKS_ONE
}

export const WEB_ARROWS18_BLOCKS_ONE = {
  name: 'Web Arrows 18',
  icon: 'web_arrows_18',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS18_BLOCKS_ONE
}

export const WEB_ARROWS19_BLOCKS_ONE = {
  name: 'Web Arrows 19',
  icon: 'web_arrows_19',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_WEB_ARROWS19_BLOCKS_ONE
}

export const SPECH1_BLOCKS_ONE = {
  name: 'Spech 1',
  icon: 'spech_1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH1_BLOCKS_ONE
}

export const SPECH2_BLOCKS_ONE = {
  name: 'Spech 2',
  icon: 'spech_2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH2_BLOCKS_ONE
}

export const SPECH3_BLOCKS_ONE = {
  name: 'Spech 3',
  icon: 'spech_3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH3_BLOCKS_ONE
}

export const SPECH4_BLOCKS_ONE = {
  name: 'Spech 4',
  icon: 'spech_4',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH4_BLOCKS_ONE
}

export const SPECH5_BLOCKS_ONE = {
  name: 'Spech 5',
  icon: 'spech_5',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH5_BLOCKS_ONE
}

export const SPECH6_BLOCKS_ONE = {
  name: 'Spech 6',
  icon: 'spech_6',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH6_BLOCKS_ONE
}

export const SPECH7_BLOCKS_ONE = {
  name: 'Spech 7',
  icon: 'spech_7',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH7_BLOCKS_ONE
}

export const SPECH8_BLOCKS_ONE = {
  name: 'Spech 8',
  icon: 'spech_8',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPECH8_BLOCKS_ONE
}

export const SMILE1_BLOCKS_ONE = {
  name: 'Smile 1',
  icon: 'smile_1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SMILE1_BLOCKS_ONE
}

export const SMILE2_BLOCKS_ONE = {
  name: 'Smile 2',
  icon: 'smile_2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SMILE2_BLOCKS_ONE
}

export const SMILE3_BLOCKS_ONE = {
  name: 'Smile 3',
  icon: 'smile_3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SMILE3_BLOCKS_ONE
}

export const SMILE4_BLOCKS_ONE = {
  name: 'Smile 4',
  icon: 'smile_4',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SMILE4_BLOCKS_ONE
}

export const SMILE5_BLOCKS_ONE = {
  name: 'Smile 5',
  icon: 'smile_5',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SMILE5_BLOCKS_ONE
}

export const RIBBON1_BLOCKS_ONE = {
  name: 'Ribbon 1',
  icon: 'ribbon_1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON1_BLOCKS_ONE
}

export const RIBBON2_BLOCKS_ONE = {
  name: 'Ribbon 2',
  icon: 'ribbon_2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON2_BLOCKS_ONE
}

export const RIBBON3_BLOCKS_ONE = {
  name: 'Ribbon 3',
  icon: 'ribbon_3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON3_BLOCKS_ONE
}

export const RIBBON4_BLOCKS_ONE = {
  name: 'Ribbon 4',
  icon: 'ribbon_4',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON4_BLOCKS_ONE
}

export const RIBBON5_BLOCKS_ONE = {
  name: 'Ribbon 5',
  icon: 'ribbon_5',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON5_BLOCKS_ONE
}

export const RIBBON6_BLOCKS_ONE = {
  name: 'Ribbon 6',
  icon: 'ribbon_6',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RIBBON6_BLOCKS_ONE
}

export const BUDGE1_BLOCKS_ONE = {
  name: 'Budge 1',
  icon: 'budge_1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE1_BLOCKS_ONE
}

export const BUDGE2_BLOCKS_ONE = {
  name: 'Budge 2',
  icon: 'budge_2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE2_BLOCKS_ONE
}

export const BUDGE3_BLOCKS_ONE = {
  name: 'Budge 3',
  icon: 'budge_3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE3_BLOCKS_ONE
}

export const BUDGE4_BLOCKS_ONE = {
  name: 'Budge 4',
  icon: 'budge_4',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE4_BLOCKS_ONE
}

export const BUDGE5_BLOCKS_ONE = {
  name: 'Budge 5',
  icon: 'budge_5',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE5_BLOCKS_ONE
}

export const BUDGE6_BLOCKS_ONE = {
  name: 'Budge 6',
  icon: 'budge_6',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE6_BLOCKS_ONE
}

export const BUDGE7_BLOCKS_ONE = {
  name: 'Budge 7',
  icon: 'budge_7',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE7_BLOCKS_ONE
}

export const BUDGE8_BLOCKS_ONE = {
  name: 'Budge 8',
  icon: 'budge_8',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE8_BLOCKS_ONE
}

export const BUDGE9_BLOCKS_ONE = {
  name: 'Budge 9',
  icon: 'budge_9',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE9_BLOCKS_ONE
}

export const BUDGE10_BLOCKS_ONE = {
  name: 'Budge 10',
  icon: 'budge_10',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE10_BLOCKS_ONE
}

export const BUDGE11_BLOCKS_ONE = {
  name: 'Budge 11',
  icon: 'budge_11',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUDGE11_BLOCKS_ONE
}

export const ROUND_TALK_BUBBLE_BLOCKS_ONE = {
  name: 'Round Talk Bubble',
  icon: 'roundTalkBubble',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ROUND_TALK_BUBBLE_BLOCKS_ONE
}

export const SEAL_STAMP_BLOCKS_ONE = {
  name: 'Seal Stamp',
  icon: 'sealStamp',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SEAL_STAMP_BLOCKS_ONE
}

export const SHIELD1_BLOCKS_ONE = {
  name: 'Shield 1',
  icon: 'shield1',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SHIELD1_BLOCKS_ONE
}

export const SHIELD2_BLOCKS_ONE = {
  name: 'Shield 2',
  icon: 'shield2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SHIELD2_BLOCKS_ONE
}

export const SHIELD3_BLOCKS_ONE = {
  name: 'Shield 3',
  icon: 'shield3',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SHIELD3_BLOCKS_ONE
}

export const SPEECH_BUBBLE_BLOCKS_ONE = {
  name: 'Speech Bubble',
  icon: 'speechBubble',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SPEECH_BUBBLE_BLOCKS_ONE
}

export const SEMICIRLE_BLOCKS_ONE = {
  name: 'Semicircle',
  icon: 'semicircle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SEMICIRLE_BLOCKS_ONE
}

export const SQUARE_STAMP_BLOCKS_ONE = {
  name: 'Square Stamp',
  icon: 'squareStamp',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SQUARE_STAMP_BLOCKS_ONE
}

export const STAR_BLOCKS_ONE = {
  name: 'Star',
  icon: 'star',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_STAR_BLOCKS_ONE
}

export const STAR_SHAPE_BLOCKS_ONE = {
  name: 'Star Shape',
  icon: 'starShape',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_STAR_SHAPE_BLOCKS_ONE
}

export const TALK_BUBBLE_BLOCKS_ONE = {
  name: 'Talk Bubble',
  icon: 'talkBubble',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TALK_BUBBLE_BLOCKS_ONE
}

export const TRIANGLE_ARROW_BLOCKS_ONE = {
  name: 'Triang leArrow',
  icon: 'triangleArrow',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TRIANGLE_ARROW_BLOCKS_ONE
}

export const BUMPY_CIRCLE_BLOCKS_ONE = {
  name: 'Bumpy Circle',
  icon: 'bumpyCircle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BUMPY_CIRCLE_BLOCKS_ONE
}

export const GEM_STONE_BLOCKS_ONE = {
  name: 'Gem Stone',
  icon: 'gemStone',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_GEM_STONE_BLOCKS_ONE
}

export const HEART_BLOCKS_ONE = {
  name: 'Heart',
  icon: 'heart',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_HEART_BLOCKS_ONE
}

export const HOLLOW_CIRCLE_BLOCKS_ONE = {
  name: 'Hollow Circle',
  icon: 'hollowCircle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_HOLLOW_CIRCLE_BLOCKS_ONE
}

export const LOCATION_PIN_BLOCKS_ONE = {
  name: 'Location Pin',
  icon: 'locationPin',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_LOCATION_PIN_BLOCKS_ONE
}

export const OCTAGRAM_BLOCKS_ONE = {
  name: 'Octagram',
  icon: 'octagram',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_OCTAGRAM_BLOCKS_ONE
}

export const RAIN_DROP_BLOCKS_ONE = {
  name: 'Rain Drop',
  icon: 'rainDrop',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_RAIN_DROP_BLOCKS_ONE
}

export const DIAMOND_BLOCKS_ONE = {
  name: 'Diamond',
  icon: 'diamond',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DIAMOND_BLOCKS_ONE
}

export const DECAGON_BLOCKS_ONE = {
  name: 'Decagon',
  icon: 'Decagon',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DECAGON_BLOCKS_ONE
}

export const HEXAGON_BLOCKS_ONE = {
  name: 'Hexagon',
  icon: 'Hexagon',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_HEXAGON_BLOCKS_ONE
}

export const OCTAGON_BLOCKS_ONE = {
  name: 'Octagon',
  icon: 'octagon',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_OCTAGON_BLOCKS_ONE
}

export const TRIANGLE_BLOCKS_ONE = {
  name: 'Triangle',
  icon: 'triangle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TRIANGLE_BLOCKS_ONE
}

export const TRAPEZE_BLOCKS_ONE = {
  name: 'Trapeze',
  icon: 'trapeze',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TRAPEZE_BLOCKS_ONE
}

export const SQUARE_BLOCKS_ONE = {
  name: 'Square',
  icon: 'square',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SQUARE_BLOCKS_ONE
}

export const OVAL = {
  name: 'Oval',
  icon: 'square',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_OVAL
}

export const ROUNDED_CORNER_SQUARE_BLOCKS_ONE = {
  name: 'Rounded Corner Square',
  icon: 'roundedcornersquare',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ROUNDED_CORNER_SQUARE_BLOCKS_ONE
}

export const PENTAGON_BLOCKS_ONE = {
  name: 'Pentagon',
  icon: 'pentagon',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PENTAGON_BLOCKS_ONE
}

export const ISOSCELES_TRIANGLE_BLOCKS_ONE = {
  name: 'Isosceles Ttriangle',
  icon: 'IsoscelesTtriangle',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ISOSCELES_TRIANGLE_BLOCKS_ONE
}

export const QUICK_FACTS_WIDGET = {
  name: 'Quick Facts',
  icon: 'quick_facts',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_QUICK_FACTS_WIDGET,
  preview: 'quickFactsMiniHorizontal'
}

export const COUNTDOWN_WIDGET = {
  name: 'Countdown',
  icon: 'countdown',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_COUNTDOWN_WIDGET,
  preview: 'countdownMiniHorizontal'
}

export const ACCORDION_WIDGET = {
  name: 'Accordion',
  icon: 'accordion-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_ACCORDION_WIDGET,
  preview: 'accordionMiniHorizontal'
}

export const BLOCKQUOTE_WIDGET = {
  name: 'Quotation #1',
  icon: 'blockquote',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_BLOCKQUOTE_WIDGET,
  preview: 'blockquoteMiniHorizontal'
}

export const HOW_IT_WORKS_WIDGET = {
  name: 'How It Works',
  icon: 'howItWorks',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_HOW_IT_WORKS_WIDGET,
  preview: 'howItWorksMiniHorizontal'
}

export const NEW_SLIDER_HORIZONTAL_WIDGET = {
  name: 'Slider Horizontal',
  icon: 'slider-2',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SLIDER_HORIZONTAL_WIDGET,
  preview: 'sliderMiniHorizontal'
}

export const NEW_SLIDER_HORIZONTAL_WIDGET_TABS = {
  name: 'Slider Horizontal (Tabs)',
  icon: 'sliderTabsMiniHorizontal',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_SLIDER_HORIZONTAL_WIDGET_TABS,
  preview: 'sliderMiniHorizontal1'
}

export const NEW_TABS_WIDGET = {
  name: 'Tabs',
  icon: 'tabs',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_TABS_WIDGET,
  preview: 'tabsMiniHorizontal'
}

export const NEW_COLUMNS_WIDGET = {
  name: 'Columns',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_COLUMNS_WIDGET,
  preview: 'columnsMiniHorizontal'
}

export const NEW_LIST_COLUMNS_WIDGET = {
  name: 'Lists',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_LISTS_COLUMNS_WIDGET,
  preview: 'listsMiniHorizontal'
}

export const NEW_CONTENT_LIST_WIDGET = {
  name: 'Lists',
  icon: 'two-columns-list',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_CONTENT_WIDGET_LIST,
  preview: 'listsMiniHorizontal'
}

export const NEW_CONTENT_BLOCK = {
  name: 'Lists',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_CONTENT_BLOCK_CONTAINER,
  preview: 'listsMiniHorizontal'
}

export const NEW_CONTENT_LIST_BLOCK = {
  name: 'Lists',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_CONTENT_LIST_BLOCK_CONTAINER,
  preview: 'listsMiniHorizontal'
}

export const NEW_PAYMENT_WIDGET = {
  name: 'Invest in Eternity',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_PAYMENT_WIDGET,
  preview: 'giveNewDesign'
}
export const NEW_DONATION_WIDGET = {
  name: 'Donation',
  icon: 'two-columns-list',
  type: TYPE_ELEMENT,
  sub: subType.SUB_TYPE_DONATION_WIDGET,
  preview: 'donation'
}
export const PRAYER_WALL_WIDGET = {
  name: 'Prayer',
  name2: 'Prayer',
  name3: 'Prayer Wall',
  icon: 'sermons',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_PRAYER_WALL_WIDGET,
  preview: 'prayerwall',
  route: 'prayer-wall'
}

export const CTA_WIDGET = {
  name: 'Call to Action #1',
  name2: 'CTA',
  name3: 'Call to Action',
  icon: 'photo',
  type: TYPE_SECTION,
  sub: subType.SUB_TYPE_CTA_WIDGET,
  preview: 'cta',
  route: 'cta'
}

export const ELEMENTS_LIST_GRID = [SECTION, IFRAME]

export const ELEMENTS_LIST_BASIC = [BUTTON, DIVIDER, SOCIAL, MENU_LIST_1, ACCORDION, DROPDOWN]
// MENU_LIST_2, MENU_LIST_3, MENU_LIST_4, MENU_LIST_5
export const ELEMENTS_LIST_TYPOGRAPHY = [HEADLINE, TEXT_BLOCK]

export const ELEMENTS_LIST_MEDIA = [IMAGE, VIDEO, SLIDER, COUNTER]

export const ELEMENTS_LIST_WIDGETS = [
  PRAYER_WALL_WIDGET,
  EVENTS_WIDGET,
  BLOG_WIDGET,
  NEWS_WIDGET,
  DEVOTIONS_WIDGET,
  READING_PLAN_WIDGET,
  PHOTO_WIDGET,
  CONTACT_US_WIDGET,
  ABOUT_US_WIDGET,
  MINISTRIES_WIDGET,
  STAFF_WIDGET,
  SERMONS_WIDGET,
  SERMONS_WIDGET2,
  LIVE_STREAMING_WIDGET,
  LIVE_STREAMING_WIDGET2,
  LIVE_NEW_WIDGET,
  APP_PAGE_WIDGET,
  PRIVACY_POLICY_WIDGET
]

export const ELEMENTS_LIST_WIDGETS_COMPACT = [
  // EVENTS_CUSTOM_WIDGET,
  // EVENTS_CUSTOM_WIDGET_BG,
  // EVENTS_CUSTOM_WIDGET_BANNER,
  // EVENTS_CUSTOM_WIDGET_MINI,
  // EVENTS_CUSTOM_WIDGET_NO_BG,
  // EVENTS_CUSTOM_WIDGET_CALENDAR,
  // EVENTS_CUSTOM_WIDGET_CALENDAR_IMG,
  ADDRESS_COPY,
  PODCASTS,
  WATCH_US_ON_TV_TYPE2,
  QUOTE_WIDGET,
  DIRECTIONS_ICON,
  MINISTRY,
  VIDEO_FULL,
  CLOCK_WIDGET,
  // MINISTRY2,
  // MISSION,
  // DEVOTIONAL_SECTION,
  // READING_SECTION,
  // LATEST_UPDATE_HORIZONTAL_WIDGET,
  // EVENTS_COMPACT_HORIZONTAL_WIDGET2,
  EVENTS_WIDGET_COMPACT,
  BLOG_WIDGET_COMPACT,
  NEWS_WIDGET_COMPACT,
  DEVOTIONS_COMPACT,
  READING_PLAN_COMPACT_WIDGET,
  PHOTO_COMPACT_WIDGET,
  VISITOR_WIDGET,
  SUBSCRIBE_WIDGET,
  WELCOME_WIDGET,
  MOBILE_APP_WIDGET,
  TV_APP_WIDGET,
  GIVE_WIDGET,
  STREAM_WIDGET,
  SERMONS_WIDGET_COMPACT,
  LATEST_WIDGET_COMPACT,
  WATCH_US_ON_TV_WIDGET,
  NEWS_WIDGET_COMPACT_HORIZONTAL,
  BLOGS_WIDGET_COMPACT_HORIZONTAL,
  SERMONS_WIDGET_COMPACT_HORIZONTAL,
  MINISTRIES_COMPACT_HORIZONTAL_WIDGET,
  EVENTS_COMPACT_HORIZONTAL_WIDGET,
  READING_PLAN_COMPACT_HORIZONTAL_WIDGET,
  NEW_PAYMENT_WIDGET,
  NEW_DONATION_WIDGET
]

export const ELEMENTS_LIST_CTA = [CTA_WIDGET]

export const ELEMENTS_LIST_OTHER_WIDGETS = [HOW_IT_WORKS_WIDGET]

export const ELEMENTS_LIST_TEXT_BLOCK_NEW = [TEXT_BLOCK]

export const ELEMENTS_LIST_CONTENT_BLOCKS = []
export const ELEMENTS_LIST_HOME_PAGE_BASIC = [MAP_TEMPLATE, SERMONS_WIDGET2]

export const ELEMENTS_LIST_PHOTOS_NEW = [PHOTOS_BLOCKS_ONE]
export const ELEMENTS_LIST_PHOTO_NEW = [PHOTO_BLOCKS_ONE]
export const ELEMENTS_LIST_VIDEOS_NEW = [VIDEOS_BLOCKS_ONE]
export const ELEMENTS_LIST_ICONS_NEW = [ICONS_BLOCKS_ONE]

export const ELEMENTS_LIST_TYPOGRAPHY_NEW = [
  HEADLINE,
  SUBHEADLINE,
  TEXT_BLOCK,
  ORNAMENT1_BLOCKS_ONE,
  ORNAMENT2_BLOCKS_ONE,
  ORNAMENT3_BLOCKS_ONE,
  ORNAMENT4_BLOCKS_ONE,
  ORNAMENT5_BLOCKS_ONE,
  ORNAMENT6_BLOCKS_ONE,
  ORNAMENT7_BLOCKS_ONE,
  ORNAMENT8_BLOCKS_ONE,
  ORNAMENT9_BLOCKS_ONE,
  ORNAMENT10_BLOCKS_ONE,
  ORNAMENT11_BLOCKS_ONE,
  ORNAMENT12_BLOCKS_ONE,
  ORNAMENT13_BLOCKS_ONE,
  ORNAMENT14_BLOCKS_ONE,
  ORNAMENT15_BLOCKS_ONE,
  ORNAMENT16_BLOCKS_ONE,
  ORNAMENT17_BLOCKS_ONE,
  ORNAMENT18_BLOCKS_ONE,
  ORNAMENT19_BLOCKS_ONE,
  ORNAMENT20_BLOCKS_ONE,
  ORNAMENT21_BLOCKS_ONE,
  ORNAMENT22_BLOCKS_ONE,
  ORNAMENT23_BLOCKS_ONE,
  ORNAMENT24_BLOCKS_ONE,
  ORNAMENT25_BLOCKS_ONE,
  ORNAMENT26_BLOCKS_ONE,
  ORNAMENT27_BLOCKS_ONE,
  ORNAMENT28_BLOCKS_ONE,
  ORNAMENT29_BLOCKS_ONE,
  ORNAMENT30_BLOCKS_ONE,
  ORNAMENT31_BLOCKS_ONE,
  ORNAMENT32_BLOCKS_ONE,
  ORNAMENT33_BLOCKS_ONE,
  ORNAMENT34_BLOCKS_ONE,
  ORNAMENT35_BLOCKS_ONE,
  ORNAMENT36_BLOCKS_ONE,
  ORNAMENT37_BLOCKS_ONE,
  ORNAMENT38_BLOCKS_ONE,
  ORNAMENT39_BLOCKS_ONE,
  ORNAMENT40_BLOCKS_ONE,
  ORNAMENT41_BLOCKS_ONE,
  ORNAMENT42_BLOCKS_ONE,
  ORNAMENT43_BLOCKS_ONE,
  ORNAMENT44_BLOCKS_ONE,
  ORNAMENT45_BLOCKS_ONE,
  ORNAMENT46_BLOCKS_ONE,
  ORNAMENT47_BLOCKS_ONE,
  ORNAMENT48_BLOCKS_ONE,
  ORNAMENT49_BLOCKS_ONE,
  ORNAMENT50_BLOCKS_ONE,
  ORNAMENT51_BLOCKS_ONE,
  ORNAMENT52_BLOCKS_ONE,
  ORNAMENT53_BLOCKS_ONE,
  ORNAMENT54_BLOCKS_ONE,
  ORNAMENT55_BLOCKS_ONE,
  ORNAMENT56_BLOCKS_ONE,
  ORNAMENT57_BLOCKS_ONE,
  ORNAMENT58_BLOCKS_ONE,
  ORNAMENT59_BLOCKS_ONE,
  ORNAMENT60_BLOCKS_ONE,
  ORNAMENT61_BLOCKS_ONE,
  ORNAMENT62_BLOCKS_ONE,
  ORNAMENT63_BLOCKS_ONE,
  ORNAMENT64_BLOCKS_ONE,
  ORNAMENT65_BLOCKS_ONE,
  ORNAMENT66_BLOCKS_ONE,
  ORNAMENT67_BLOCKS_ONE,
  ORNAMENT68_BLOCKS_ONE,
  ORNAMENT69_BLOCKS_ONE,
  ORNAMENT70_BLOCKS_ONE,
  ORNAMENT71_BLOCKS_ONE,
  ORNAMENT72_BLOCKS_ONE,
  ORNAMENT73_BLOCKS_ONE,
  ORNAMENT74_BLOCKS_ONE,
  ORNAMENT75_BLOCKS_ONE,
  ORNAMENT76_BLOCKS_ONE,
  ORNAMENT77_BLOCKS_ONE,
  ORNAMENT78_BLOCKS_ONE,
  ORNAMENT79_BLOCKS_ONE,
  ORNAMENT80_BLOCKS_ONE,
  ORNAMENT81_BLOCKS_ONE,
  ORNAMENT82_BLOCKS_ONE,
  ORNAMENT83_BLOCKS_ONE,
  ORNAMENT84_BLOCKS_ONE,
  ORNAMENT85_BLOCKS_ONE,
  ORNAMENT86_BLOCKS_ONE,
  ORNAMENT87_BLOCKS_ONE,
  ORNAMENT88_BLOCKS_ONE,
  ORNAMENT89_BLOCKS_ONE,
  ORNAMENT90_BLOCKS_ONE,
  ORNAMENT91_BLOCKS_ONE,
  ORNAMENT92_BLOCKS_ONE,
  ORNAMENT93_BLOCKS_ONE,
  ORNAMENT94_BLOCKS_ONE,
  ORNAMENT95_BLOCKS_ONE,
  ORNAMENT96_BLOCKS_ONE,
  ORNAMENT97_BLOCKS_ONE,
  ORNAMENT98_BLOCKS_ONE,
  ORNAMENT99_BLOCKS_ONE,
  ORNAMENT100_BLOCKS_ONE,
  ORNAMENT101_BLOCKS_ONE,
  ORNAMENT102_BLOCKS_ONE,
  ORNAMENT103_BLOCKS_ONE,
  ORNAMENT104_BLOCKS_ONE,
  ORNAMENT105_BLOCKS_ONE,
  ORNAMENT106_BLOCKS_ONE,
  ORNAMENT107_BLOCKS_ONE,
  ORNAMENT108_BLOCKS_ONE,
  ORNAMENT109_BLOCKS_ONE,
  ORNAMENT110_BLOCKS_ONE,
  ORNAMENT111_BLOCKS_ONE,
  ORNAMENT112_BLOCKS_ONE,
  ORNAMENT113_BLOCKS_ONE,
  ORNAMENT114_BLOCKS_ONE,
  ORNAMENT115_BLOCKS_ONE,
  ORNAMENT116_BLOCKS_ONE,
  ORNAMENT117_BLOCKS_ONE,
  ORNAMENT118_BLOCKS_ONE,
  ORNAMENT119_BLOCKS_ONE,
  ORNAMENT120_BLOCKS_ONE,
  ORNAMENT121_BLOCKS_ONE,
  ORNAMENT122_BLOCKS_ONE,
  ORNAMENT123_BLOCKS_ONE,
  ORNAMENT124_BLOCKS_ONE,
  ORNAMENT125_BLOCKS_ONE,
  ORNAMENT126_BLOCKS_ONE,
  ORNAMENT127_BLOCKS_ONE,
  ORNAMENT128_BLOCKS_ONE,
  ORNAMENT129_BLOCKS_ONE,
  ORNAMENT130_BLOCKS_ONE,
  ORNAMENT131_BLOCKS_ONE,
  ORNAMENT132_BLOCKS_ONE,
  SWASH1_BLOCKS_ONE,
  SWASH2_BLOCKS_ONE,
  SWASH3_BLOCKS_ONE,
  SWASH4_BLOCKS_ONE,
  SWASH5_BLOCKS_ONE,
  SWASH6_BLOCKS_ONE,
  SWASH7_BLOCKS_ONE,
  SWASH8_BLOCKS_ONE,
  SWASH9_BLOCKS_ONE,
  SWASH10_BLOCKS_ONE,
  LOGO1_BLOCKS_ONE,
  LOGO2_BLOCKS_ONE,
  LOGO3_BLOCKS_ONE,
  LOGO4_BLOCKS_ONE,
  LOGO5_BLOCKS_ONE,
  LOGO6_BLOCKS_ONE,
  BRIHTON1_BLOCKS_ONE,
  BRIHTON2_BLOCKS_ONE,
  BRIHTON3_BLOCKS_ONE,
  BRIHTON4_BLOCKS_ONE,
  BRIHTON5_BLOCKS_ONE,
  BRIHTON6_BLOCKS_ONE,
  BRIHTON7_BLOCKS_ONE,
  BRIHTON8_BLOCKS_ONE,
  BRIHTON9_BLOCKS_ONE,
  BRIHTON10_BLOCKS_ONE,
  BRIHTON11_BLOCKS_ONE,
  BRIHTON12_BLOCKS_ONE,
  BRIHTON13_BLOCKS_ONE,
  BRIHTON14_BLOCKS_ONE,
  JOVIAL1_BLOCKS_ONE,
  JOVIAL2_BLOCKS_ONE,
  JOVIAL3_BLOCKS_ONE,
  JOVIAL4_BLOCKS_ONE,
  JOVIAL5_BLOCKS_ONE,
  JOVIAL6_BLOCKS_ONE,
  JOVIAL7_BLOCKS_ONE,
  JOVIAL8_BLOCKS_ONE,
  JOVIAL9_BLOCKS_ONE,
  JOVIAL10_BLOCKS_ONE,
  JOVIAL11_BLOCKS_ONE,
  JOVIAL12_BLOCKS_ONE,
  JOVIAL13_BLOCKS_ONE,
  JOVIAL14_BLOCKS_ONE,
  JOVIAL15_BLOCKS_ONE,
  JOVIAL16_BLOCKS_ONE,
  JOVIAL17_BLOCKS_ONE,
  JOVIAL18_BLOCKS_ONE,
  JOVIAL19_BLOCKS_ONE,
  JOVIAL20_BLOCKS_ONE,
  JOVIAL21_BLOCKS_ONE,
  JOVIAL22_BLOCKS_ONE,
  JOVIAL23_BLOCKS_ONE,
  JOVIAL24_BLOCKS_ONE,
  JOVIAL25_BLOCKS_ONE,
  JOVIAL26_BLOCKS_ONE,
  JOVIAL27_BLOCKS_ONE,
  JOVIAL28_BLOCKS_ONE,
  JOVIAL29_BLOCKS_ONE,
  JOVIAL30_BLOCKS_ONE,
  JOVIAL31_BLOCKS_ONE,
  JOVIAL32_BLOCKS_ONE,
  JOVIAL33_BLOCKS_ONE,
  JOVIAL34_BLOCKS_ONE,
  JOVIAL35_BLOCKS_ONE,
  JOVIAL36_BLOCKS_ONE,
  JOVIAL37_BLOCKS_ONE,
  JOVIAL38_BLOCKS_ONE,
  JOVIAL39_BLOCKS_ONE,
  JOVIAL40_BLOCKS_ONE,
  JOVIAL41_BLOCKS_ONE,
  JOVIAL42_BLOCKS_ONE,
  JOVIAL43_BLOCKS_ONE,
  JOVIAL44_BLOCKS_ONE,
  JOVIAL45_BLOCKS_ONE,
  JOVIAL46_BLOCKS_ONE,
  JOVIAL47_BLOCKS_ONE,
  JOVIAL48_BLOCKS_ONE,
  JOVIAL49_BLOCKS_ONE,
  JOVIAL50_BLOCKS_ONE,
  JOVIAL51_BLOCKS_ONE,
  JOVIAL52_BLOCKS_ONE,
  JOVIAL53_BLOCKS_ONE,
  JOVIAL54_BLOCKS_ONE,
  JOVIAL55_BLOCKS_ONE,
  JOVIAL56_BLOCKS_ONE,
  JOVIAL57_BLOCKS_ONE,
  JOVIAL58_BLOCKS_ONE,
  JOVIAL59_BLOCKS_ONE,
  JOVIAL60_BLOCKS_ONE,
  JOVIAL61_BLOCKS_ONE,
  JOVIAL62_BLOCKS_ONE,
  JOVIAL63_BLOCKS_ONE,
  JOVIAL64_BLOCKS_ONE,
  JOVIAL65_BLOCKS_ONE,
  JOVIAL66_BLOCKS_ONE,
  JOVIAL67_BLOCKS_ONE,
  JOVIAL68_BLOCKS_ONE,
  JOVIAL69_BLOCKS_ONE,
  JOVIAL70_BLOCKS_ONE,
  JOVIAL71_BLOCKS_ONE,
  JOVIAL72_BLOCKS_ONE,
  JOVIAL73_BLOCKS_ONE,
  JOVIAL74_BLOCKS_ONE,
  JOVIAL75_BLOCKS_ONE,
  BRIXTON_SANS1_BLOCKS_ONE,
  BRIXTON_SANS2_BLOCKS_ONE,
  BRIXTON_SANS3_BLOCKS_ONE,
  BRIXTON_SANS4_BLOCKS_ONE,
  BRIXTON_SANS5_BLOCKS_ONE,
  BRIXTON_SANS6_BLOCKS_ONE,
  BRIXTON_SANS7_BLOCKS_ONE,
  BRIXTON_SANS8_BLOCKS_ONE,
  BRIXTON_SANS9_BLOCKS_ONE,
  BRIXTON_SANS10_BLOCKS_ONE,
  BRIXTON_SANS11_BLOCKS_ONE,
  BRIXTON_SANS12_BLOCKS_ONE,
  BRIXTON_SANS13_BLOCKS_ONE,
  BRIXTON_SANS14_BLOCKS_ONE,
  BRIXTON_SANS15_BLOCKS_ONE,
  BRIXTON_SANS16_BLOCKS_ONE,
  BRIXTON_SANS17_BLOCKS_ONE,
  BRIXTON_SANS18_BLOCKS_ONE,
  BRIXTON_SANS19_BLOCKS_ONE,
  BRIXTON_SANS20_BLOCKS_ONE,
  BRIXTON_SANS21_BLOCKS_ONE,
  BRIXTON_SANS22_BLOCKS_ONE,
  BRIXTON_SANS23_BLOCKS_ONE,
  BRIXTON_SANS24_BLOCKS_ONE,
  BRIXTON_SANS25_BLOCKS_ONE,
  BRIXTON_SANS26_BLOCKS_ONE,
  BRIXTON_SANS27_BLOCKS_ONE,
  BRIXTON_SANS28_BLOCKS_ONE,
  BRIXTON_SANS29_BLOCKS_ONE,
  BRIXTON_SANS30_BLOCKS_ONE,
  BRIXTON_SANS31_BLOCKS_ONE,
  BRIXTON_SANS32_BLOCKS_ONE,
  BRIXTON_SANS33_BLOCKS_ONE,
  BRIXTON_SANS34_BLOCKS_ONE,
  BRIXTON_SANS35_BLOCKS_ONE,
  BRIXTON_SANS36_BLOCKS_ONE,
  BRIXTON_SANS37_BLOCKS_ONE,
  BRIXTON_SANS38_BLOCKS_ONE,
  BRIXTON_SANS39_BLOCKS_ONE,
  BRIXTON_SANS40_BLOCKS_ONE,
  BRIXTON_SANS41_BLOCKS_ONE,
  BRIXTON_SANS42_BLOCKS_ONE,
  BRIXTON_SANS43_BLOCKS_ONE,
  BRIXTON_SANS44_BLOCKS_ONE,
  BRIXTON_SANS45_BLOCKS_ONE,
  BRIXTON_SANS46_BLOCKS_ONE,
  BRIXTON_SANS47_BLOCKS_ONE,
  BRIXTON_SANS48_BLOCKS_ONE
  // TEXTURES1_BLOCKS_ONE
]

export const ELEMENTS_LIST_SHAPES_NEW = [
  SHAPES_BLOCKS_ONE,
  SQUARE_BLOCKS_ONE,
  ROUNDED_CORNER_SQUARE_BLOCKS_ONE,
  // OVAL,
  TRIANGLE_BLOCKS_ONE,
  ISOSCELES_TRIANGLE_BLOCKS_ONE,
  TRAPEZE_BLOCKS_ONE,
  PENTAGON_BLOCKS_ONE,
  HEXAGON_BLOCKS_ONE,
  OCTAGON_BLOCKS_ONE,
  DECAGON_BLOCKS_ONE,
  OCTAGRAM_BLOCKS_ONE,
  TRIANGLE_ARROW_BLOCKS_ONE,
  STAR_SHAPE_BLOCKS_ONE,
  STAR_BLOCKS_ONE,
  DIAMOND_BLOCKS_ONE,
  GEM_STONE_BLOCKS_ONE,
  SEMICIRLE_BLOCKS_ONE,
  HOLLOW_CIRCLE_BLOCKS_ONE,
  SQUARE_STAMP_BLOCKS_ONE,
  SEAL_STAMP_BLOCKS_ONE,
  BUMPY_CIRCLE_BLOCKS_ONE,
  LOCATION_PIN_BLOCKS_ONE,
  RAIN_DROP_BLOCKS_ONE,
  HEART_BLOCKS_ONE,
  SPEECH_BUBBLE_BLOCKS_ONE,
  TALK_BUBBLE_BLOCKS_ONE,
  ROUND_TALK_BUBBLE_BLOCKS_ONE,
  SHIELD1_BLOCKS_ONE,
  SHIELD2_BLOCKS_ONE,
  SHIELD3_BLOCKS_ONE,
  BUDGE1_BLOCKS_ONE,
  BUDGE2_BLOCKS_ONE,
  BUDGE3_BLOCKS_ONE,
  BUDGE4_BLOCKS_ONE,
  BUDGE5_BLOCKS_ONE,
  BUDGE6_BLOCKS_ONE,
  BUDGE7_BLOCKS_ONE,
  BUDGE8_BLOCKS_ONE,
  BUDGE9_BLOCKS_ONE,
  BUDGE10_BLOCKS_ONE,
  BUDGE11_BLOCKS_ONE,
  RIBBON1_BLOCKS_ONE,
  RIBBON2_BLOCKS_ONE,
  RIBBON3_BLOCKS_ONE,
  RIBBON4_BLOCKS_ONE,
  RIBBON5_BLOCKS_ONE,
  RIBBON6_BLOCKS_ONE,
  SMILE1_BLOCKS_ONE,
  SMILE2_BLOCKS_ONE,
  SMILE3_BLOCKS_ONE,
  SMILE4_BLOCKS_ONE,
  SMILE5_BLOCKS_ONE,
  SPECH1_BLOCKS_ONE,
  SPECH2_BLOCKS_ONE,
  SPECH3_BLOCKS_ONE,
  SPECH4_BLOCKS_ONE,
  SPECH5_BLOCKS_ONE,
  SPECH6_BLOCKS_ONE,
  SPECH7_BLOCKS_ONE,
  SPECH8_BLOCKS_ONE,
  WEB_ARROWS1_BLOCKS_ONE,
  WEB_ARROWS2_BLOCKS_ONE,
  WEB_ARROWS3_BLOCKS_ONE,
  WEB_ARROWS4_BLOCKS_ONE,
  WEB_ARROWS5_BLOCKS_ONE,
  WEB_ARROWS6_BLOCKS_ONE,
  WEB_ARROWS7_BLOCKS_ONE,
  WEB_ARROWS8_BLOCKS_ONE,
  WEB_ARROWS9_BLOCKS_ONE,
  WEB_ARROWS10_BLOCKS_ONE,
  WEB_ARROWS11_BLOCKS_ONE,
  WEB_ARROWS12_BLOCKS_ONE,
  WEB_ARROWS13_BLOCKS_ONE,
  WEB_ARROWS14_BLOCKS_ONE,
  WEB_ARROWS15_BLOCKS_ONE,
  WEB_ARROWS16_BLOCKS_ONE,
  WEB_ARROWS17_BLOCKS_ONE,
  WEB_ARROWS18_BLOCKS_ONE,
  WEB_ARROWS19_BLOCKS_ONE
]

export const ELEMENTS_LIST_TITLE_BLOCK_NEW = [HEADLINE]

export const ELEMENTS_LIST_MENU_BLOCK = [MENU_LIST, MENU_LIST_1, MENU_LIST_2, MENU_LIST_3, MENU_LIST_4, MENU_LIST_5, MENU_LIST_6, MENU_LIST_7, MENU_LIST_8, MENU_LIST_9, MENU_LIST_10, MENU_LIST_11, MENU_LIST_PULL_OUT]
// MENU_LIST_12, MENU_LIST_13, MENU_LIST_14
export const ELEMENTS_LIST_SLIDERS_BLOCK_NEW = [SLIDER]
// NEW_SLIDER_HORIZONTAL_WIDGET, NEW_SLIDER_HORIZONTAL_WIDGET_TABS

export const ELEMENTS_LIST_IMAGE_BLOCK_NEW = [IMAGE, ICON]

export const ELEMENTS_LIST_VIDEO_BLOCK_NEW = [VIDEO]

// export const ELEMENTS_LIST_BUTTON_BLOCK = [BUTTON]

export const ELEMENTS_LIST_DIVIDER_BLOCK_NEW = [DIVIDER, DIVIDER_VERTICAL]

export const ELEMENTS_LIST_SOCIAL_BLOCK = [SOCIAL]
// export const ELEMENTS_LIST_SOCIAL_BLOCK = [SOCIAL, NEW_DESIGN_SOCIAL]

export const ELEMENTS_LIST_QUOTATIONS_NEW = [BLOCKQUOTE_WIDGET]

export const ELEMENTS_LIST_COUNTDOWN = [COUNTDOWN_WIDGET]

export const ELEMENTS_COLUMNS = [NEW_COLUMNS_WIDGET]

export const ELEMENTS_LIST_TESTIMONIALS = [TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET]

export const ELEMENTS_LIST_QUICK_FACTS = [QUICK_FACTS_WIDGET]

export const ELEMENTS_LIST_COLUMNS = [NEW_LIST_COLUMNS_WIDGET]

export const ELEMENTS_LIST_TABS = [NEW_TABS_WIDGET]

export const ELEMENTS_LIST_ACCORDION = [ACCORDION_WIDGET]

export const ELEMENTS_LIST_CHART = [CHART_WIDGET]

export const ELEMENTS_LIST_FOOTER = [
  FOOTER_ONE,
  FOOTER_SMALL,
  FOOTER_TWO,
  FOOTER_THREE,
  FOOTER_FOUR,
  FOOTER_FIVE,
  FOOTER_SIX,
  FOOTER_SEVEN,
  FOOTER_EIGHT
]
