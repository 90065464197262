import { UPDATE_FORM_MODEL, UPDATE_FORM_VALIDATOR } from '../mutations-types'

const state = {
  formValidator: {},
  formModel: {}
}

const getters = {
  formValidator: state => state.formValidator,
  formModel: state => state.formModel
}

const actions = {
  updateFormValidator ({ commit }, data) {
    commit(UPDATE_FORM_VALIDATOR, data)
  },
  updateFormModel ({ commit }, data) {
    commit(UPDATE_FORM_MODEL, data)
  }
}

const mutations = {
  [UPDATE_FORM_VALIDATOR] (state, data) {
    state.formValidator = data
  },
  [UPDATE_FORM_MODEL] (state, data) {
    state.formModel = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
