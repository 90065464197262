export const tabsNames = {
  requests: 'Prayer Requests',
  reports: 'Praise Reports',
  posts: 'My Posts',
  churchFinder: 'Fellowship Finder',
  profile: 'Profile'
}

export const prayButtonLabels = {
  default: 'I will pray',
  clicked: 'Prayed'
}

export const BUTTON_LABELS = {
  addRequest: 'Add Request'
}

export const cardColors = [
  '#F5F5FC',
  '#F3F7FA',
  '#F3FAFA',
  '#F3FAF6',
  '#F4FAF3',
  '#F9FAF3',
  '#FAF8F3',
  '#FAF6F3',
  '#FAF3F3',
  '#FAF3F9'
]
